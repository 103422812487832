import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormStateServiceService } from '../services/state/form-state-service.service';
import { AppState } from '../services/state/state';
import { StepsService } from '../services/steps/steps.service';

@Component({
	selector: 'app-in-dev',
	templateUrl: './app-in-dev.component.html',
	styleUrls: ['./app-in-dev.component.scss'],
})
export class AppInDevComponent {
	public continueEdit: boolean = false;

	public inDevApp: boolean = true;
	public stateSteps: AppState;
	private appConfigId: string;

	setPlayVideo() {
		throw new Error('Method not implemented.');
	}
	playVideo() {
		throw new Error('Method not implemented.');
	}

	video = {
		preview: 'https://www.youtube.com/watch?v=_du5gqx8RIE',
		thumbnail: '../../../assets/thumbnail.png',
	};

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private stepsService: StepsService,
		private stepFormStateService: FormStateServiceService
	) {}

	ngOnInit(): void {}

	onNavigateAcceptTerms() {}
}
