import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { HeaderComponent } from './layout/header/header.component';
import { PageLayoutComponent } from './layout/page-layout/page-layout.component';

@NgModule({
	declarations: [HeaderComponent, PageLayoutComponent],
	imports: [CommonModule, MatFormFieldModule, ReactiveFormsModule, MatInputModule, MatStepperModule],
	exports: [HeaderComponent, PageLayoutComponent],
})
export class CoreModule {}
