import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-actions-steps',
	templateUrl: './actions-steps.component.html',
	styleUrls: ['./actions-steps.component.scss'],
})
export class ActionsStepsComponent {
	@Input()
	isPreviousDisabled: boolean = true;
	@Output()
	handlePreviousClick = new EventEmitter();
	@Output()
	handleSaveAndQuit = new EventEmitter();
	@Output()
	handleSaveAndNext = new EventEmitter();

	onClickPrevious($event: MouseEvent) {
		this.handlePreviousClick.emit($event);
	}
	onClickSaveAndQuit($event: MouseEvent) {
		this.handleSaveAndQuit.emit($event);
	}
	onClickSaveAndContinue($event: MouseEvent) {
		this.handleSaveAndNext.emit($event);
	}
}
