import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

// Verifica se há algo no localStorage
if (localStorage.length > 0) {
	// Limpa o localStorage se houver algo
	localStorage.clear();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.error(err));
