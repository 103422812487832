import { STEPPER_GLOBAL_OPTIONS, StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, OnInit, effect } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { StepsFormBuilderService } from '../services/form-builder/steps-form-builder.service';
import { INITIAL_VALUES } from '../services/state/constants';
import { FormStateServiceService } from '../services/state/form-state-service.service';
import { AppState } from '../services/state/state';
import { StepsService } from '../services/steps/steps.service';
import { IAppCreationStep } from './app-creation';
import { STEPS_VALUES } from './constants';

@Component({
	selector: 'creation-steps',
	templateUrl: './app-creation-steps.component.html',
	styleUrls: ['./app-creation-steps.component.scss'],
	providers: [
		{
			provide: STEPPER_GLOBAL_OPTIONS,
			useValue: { displayDefaultIndicatorType: false },
		},
	],
})
export class AppCreationStepsComponent implements OnInit {
	// TODO: get this value from the plan selected
	public isLite: boolean = true;
	public selectedStepIndex: number = 0;
	public previousStepIndex: number = 0;
	public steps: IAppCreationStep[] = STEPS_VALUES;
	public form: FormGroup = new FormGroup({});
	public state: AppState = INITIAL_VALUES;
	public stepsFormatted: IAppCreationStep[] = this.steps.filter((step) => step.isLite === this.isLite);
	public stage = 0;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private stepsFormService: StepsFormBuilderService,
		private stepsService: StepsService,
		private stepFormStateService: FormStateServiceService
	) {
		effect(() => {
			this.state = this.stepFormStateService.getState();
			if (this.state.stage! > 0) {
				const stage = this.stepsFormatted.find((step) => step.stage === this.state.stage);
				if (stage) {
					const stageIndex = this.stepsFormatted.findIndex((step) => step === stage);

					this.selectedStepIndex = stageIndex;
					this.navigateToStep();
				}
			}
		});
	}

	ngOnInit(): void {
		this.form = this.stepsFormService.getForm();
	}

	selectionChanged(event: StepperSelectionEvent) {
		this.selectedStepIndex = event.selectedIndex;
		this.previousStepIndex = event.previouslySelectedIndex;
		this.navigateToStep();
	}

	identify(_: number, item: IAppCreationStep): string {
		return item.path;
	}

	private navigateToStep() {
		this.router.navigate([this.stepsFormatted[this.selectedStepIndex].path], {
			relativeTo: this.activatedRoute,
		});
	}

	saveAndQuit() {
		this.onSubmit(true);
	}
	onSubmit(isFinished: boolean) {
		const state = {
			...this.state,
			stage: STEPS_VALUES.find((stage) => stage.path === this.stepsFormatted[this.selectedStepIndex].path)?.stage,
		};

		const transformedObject = formatObject(state);
		const url = this.router.url;
		const appConfigId = url.split('/')[2];
		this.stepsService.save(appConfigId, transformedObject, isFinished).subscribe(() => {});
	}
	onSaveAndNext(stepper: MatStepper) {
		this.onSubmit(false);
		stepper.next();
	}

	onPreviousStep(stepper: MatStepper) {
		stepper.previous();
	}
}
function formatObject(object: AppState) {
	console.log(object);
	const transformedObject = {
		name: object.store.name,
		plan: '',
		icons_type: '',
		stage: object.stage,
		mode: object.home_screen.home.selectTheme,
		notification_icon: object.login.notification_icon,
		colors: {
			light: {
				background: object.home_screen.colors.light.background,
				primary: object.home_screen.colors.light.primary,
				surface: object.home_screen.colors.light.surface,
				accent: object.home_screen.colors.light.accent,
				on_accent: object.home_screen.colors.light.on_accent,
			},
			dark: {
				background: object.home_screen.colors.dark.background,
				primary: object.home_screen.colors.dark.primary,
				surface: object.home_screen.colors.dark.surface,
				accent: object.home_screen.colors.dark.accent,
				on_accent: object.home_screen.colors.light.on_accent,
			},
		},
		splash: {
			logo: object.splash.logo,
			background: object.splash.background,
			logo_color: object.splash.logo_color,
		},
		login: {
			logo: object.login.logo,
		},
		home: {
			logo: object.home_screen.home.logo,
			news: '',
			preaching: '',
			labels: {
				higlights: '',
				radios: '',
				news: '',
			},
		},
		menus: {
			profile: {
				type: '',
				items: [
					{
						title: '',
						action: '',
						image: '',
					},
				],
			},
			more: {
				type: '',
				items: [
					{
						title: '',
						action: '',
						image: '',
						args: [
							{
								type: '',
								value: '',
							},
						],
					},
				],
			},
		},
		store: {
			name: object.store.name,
			cellphone_name: object.store.cellphone_name,
			icon: object.store.icon,
			images: object.store.images,
			colors: object.store.colors,
		},
	};

	return transformedObject;
}
