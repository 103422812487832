<div class="gap-16 button-container">
	<button
		class="matcha-button-lg  {{ isPreviousDisabled ? 'background-disabled' : 'bg-dark' }} cursor-pointer"
		style="width: 100%; height: 56px"
		mat-raised-button
		color="primary"
		disabled="{{ isPreviousDisabled }}"
		type="button"
		(click)="onClickPrevious($event)">
		<div class="d-flex-center-center gap-16">
			<h5 class="title font-weight-500 font-size-16 pt-12">Anterior</h5>
		</div>
	</button>
	<button
		class="matcha-button-lg bg-transparent cursor-pointer"
		style="width: 100%; height: 56px"
		mat-stroked-button
		color="primary"
		type="button"
		(click)="onClickSaveAndQuit($event)"
		*ngIf="false"
		tabindex="-1">
		<div class="d-flex-center-center gap-16">
			<h5 class="title font-weight-500 font-size-16 pt-12">Salvar e continuar depois</h5>
		</div>
	</button>
	<button
		class="matcha-button-lg bg-dark cursor-pointer button-save-continue"
		style="width: 100%; height: 56px"
		mat-raised-button
		type="button"
		(click)="onClickSaveAndContinue($event)"
		tabindex="-1">
		<div class="d-flex-center-center gap-16">
			<h5 class="title font-weight-500 font-size-16 pt-12">Salvar e continuar</h5>
		</div>
	</button>
</div>
