import { Component, effect } from '@angular/core';
import { FormStateServiceService } from 'src/app/features/services/state/form-state-service.service';
import { isColorDark } from 'src/utils/isColorDark';

@Component({
	selector: 'preview-cards',
	templateUrl: './preview-cards.component.html',
	styleUrls: ['./preview-cards.component.scss'],
})
export class PreviewCardsComponent {
	public backgroundColor: string = '';
	public theme: 'light' | 'dark' = 'light';

	public luminanceBgColor: boolean = false;

	constructor(private stepsStateService: FormStateServiceService) {
		effect(() => {
			this.theme = this.stepsStateService.getState().home_screen.home.selectTheme as 'light' | 'dark';
			this.backgroundColor = this.stepsStateService.getState().home_screen.colors[this.theme].background;
			this.luminanceBgColor = isColorDark(this.backgroundColor);
		});
	}
}
