import { Component, EventEmitter } from '@angular/core';

@Component({
	selector: 'upload-video',
	templateUrl: './upload-video.component.html',
	styleUrls: ['./upload-video.component.scss'],
})
export class UploadVideoComponent {
	select_video = new EventEmitter();

	constructor() {}

	videoChangeEvent($event: Event, logo: string, width: string, aspectRatio: string) {
		this.select_video.emit({
			event: $event,
			logo,
			width,
			aspectRatio,
		});
	}
}
