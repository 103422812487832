import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'menu-list-card',
	templateUrl: './menu-list.component.html',
	styleUrls: ['./menu-list.component.scss'],
})
export class MenuListComponent {
	@Output()
	changeForDefault = new EventEmitter();

	@Input() menuItem: {
		id: number;
	} = { id: 0 };

	constructor() {}

	ngOnInit(): void {}

	onDefaultIcon(menuIconId: number) {
		this.changeForDefault.emit(menuIconId);
	}
}
