<section class="background-surface-alpha border-radius-8">
	<div class="d-flex-column gap-24 p-24 overflow-y-auto">
		<h4
			*ngIf="!!title"
			class="title">
			<span class="title-bullet background-surface-inverse h-24 w-8"></span>
			{{ title }}
		</h4>
		<ng-content></ng-content>
	</div>
</section>
