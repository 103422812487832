import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

import { ColorPickerModule } from 'ngx-color-picker';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CardInfoComponent } from './components/card-info/card-info.component';
import { CardTutorialComponent } from './components/card-tutorial/card-tutorial.component';
import { CellphoneComponent } from './components/cellphone/cellphone.component';
import { CardColorComponent } from './components/change-theme/card-color/card-color.component';
import { ChangeThemeComponent } from './components/change-theme/change-theme.component';
import { InputComponent } from './components/input/input.component';
import { CardSectionComponent } from './components/layout/card-section/card-section.component';
import { CardStepTitleComponent } from './components/layout/card-step-title/card-step-title.component';
import { SectionPageComponent } from './components/layout/section-page/section-page.component';
import { MenuListComponent } from './components/menu-list/menu-list.component';
import { PreviewLoginComponent } from './components/preview-login/preview-login.component';
import { PreviewCardsComponent } from './components/preview-logo-and-theme/preview-cards/preview-cards.component';
import { PreviewLogoAndThemeComponent } from './components/preview-logo-and-theme/preview-logo-and-theme.component';
import { PreviewSplashComponent } from './components/preview-splash/preview-splash.component';
import { PreviewStoreComponent } from './components/preview-store/preview-store.component';
import { PreviewComponent } from './components/preview/preview.component';
import { SelectColorComponent } from './components/select-color/select-color.component';
import { SelectInputIconComponent } from './components/select-input-icon/select-input-icon.component';
import { UploadIconComponent } from './components/select-input-icon/upload-icon/upload-icon.component';
import { UploadImageComponent } from './components/upload-image/upload-image.component';
import { UploadVideoComponent } from './components/upload-video/upload-video.component';
import { FixDivDirective } from './directives/fix-div.directive';

@NgModule({
	declarations: [
		SectionPageComponent,
		CardSectionComponent,
		CardStepTitleComponent,
		CardInfoComponent,
		CellphoneComponent,
		CardTutorialComponent,
		PreviewComponent,
		InputComponent,
		SelectColorComponent,
		ChangeThemeComponent,
		CardColorComponent,
		UploadImageComponent,
		UploadVideoComponent,
		SelectInputIconComponent,
		UploadIconComponent,
		MenuListComponent,
		PreviewLogoAndThemeComponent,
		PreviewSplashComponent,
		PreviewLoginComponent,
		PreviewStoreComponent,
		PreviewCardsComponent,
		FixDivDirective,
	],
	imports: [
		CommonModule,
		MatFormFieldModule,
		MatInputModule,
		FormsModule,
		ReactiveFormsModule,
		MatSelectModule,
		MatIconModule,
		MatButtonModule,
		MatSlideToggleModule,
		ColorPickerModule,
		MatDialogModule,
		MatMenuModule,
		MatSnackBarModule,
		MatProgressSpinnerModule,
		MatRadioModule,
	],
	exports: [
		SectionPageComponent,
		CardSectionComponent,
		CardStepTitleComponent,
		CardInfoComponent,
		CellphoneComponent,
		CardTutorialComponent,
		PreviewComponent,
		InputComponent,
		SelectColorComponent,
		ChangeThemeComponent,
		CardColorComponent,
		UploadImageComponent,
		UploadVideoComponent,
		SelectInputIconComponent,
		UploadIconComponent,
		MenuListComponent,
		PreviewLogoAndThemeComponent,
		PreviewSplashComponent,
		PreviewLoginComponent,
		PreviewStoreComponent,
		PreviewCardsComponent,
		FixDivDirective,
	],
})
export class SharedModule {}
