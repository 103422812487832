<button
	class="button-selecte-item"
	[matMenuTriggerFor]="iconMenu">
	<span
		*ngIf="selectedIcon && selectedIcon.startsWith('i-')"
		class="{{ selectedIcon }}"></span>
	<div *ngIf="selectedIcon">
		<img
			*ngIf="!selectedIcon.startsWith('i-')"
			[src]="selectedIcon"
			alt="Ícone selecionado" />
	</div>
	{{ selectedIcon ? '' : 'Ícone' }}
</button>

<mat-menu #iconMenu="matMenu">
	<div class="flex-wrap">
		<button
			mat-button
			*ngFor="let icon of iconList"
			(click)="selectIcon(icon)">
			<span class="{{ icon }}"></span>
		</button>
	</div>

	<div class="p-16">
		<upload-icon></upload-icon>
	</div>
</mat-menu>
