<div class="background-surface border-radius-8 d-flex-align-center-justify-between pl-16 pr-24">
	<div class="w-100-p">
		<ng-content></ng-content>
	</div>
	<div class="d-flex-align-center gap-16">
		<button
			(click)="onDefaultIcon(menuItem.id)"
			mat-icon-button>
			<span class="i-matcha-action_reload color-disabled"></span>
		</button>
		<!-- <button
			(click)="deleteCreatedShortcut(shortcut.id)"
			mat-icon-button>
			<span class="i-matcha-delete matcha-color-disabled"></span>
		</button> -->
		<span class="i-matcha-drag-dots color-disabled"></span>
	</div>
</div>
