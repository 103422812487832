import { Component, Input, OnInit, effect } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { FormStateServiceService } from 'src/app/features/services/state/form-state-service.service';
import { ICardColor } from './@types/change-theme.types';
import { CHANGE_THEME_CARDS } from './constants/change-theme.constantes';

@Component({
	selector: 'change-theme',
	templateUrl: './change-theme.component.html',
	styleUrls: ['./change-theme.component.scss'],
})
export class ChangeThemeComponent implements OnInit {
	@Input()
	public change_theme_colors: AbstractControl<any, any> | null = new FormControl();
	@Input()
	public change_select_theme: AbstractControl<any, any> | null = new FormControl();
	//TODO - Add option to advanced change theme colors
	isShowAdvancedOptionsTheme: boolean = false;
	public colors = '';
	cards: ICardColor[] = CHANGE_THEME_CARDS;

	public isLight = true;

	public selectedTheme: 'light' | 'dark';

	constructor(private stepsStateService: FormStateServiceService) {
		effect(() => {
			this.selectedTheme = this.stepsStateService.getState().home_screen.home.selectTheme;
			this.change_theme_colors?.setValue(this.stepsStateService.getState().home_screen.colors);
		});
	}

	ngOnInit(): void {
		this.cards = CHANGE_THEME_CARDS.map((card) => {
			const colorLight = this.change_theme_colors?.value.light[card.ref];
			const colorDark = this.change_theme_colors?.value.dark[card.ref];

			return {
				...card,
				color: card.isDark ? colorDark : colorLight,
			};
		});
	}
	onColorThemeChange(color: { newColor: string }, isDark: boolean, ref: string) {
		const formGroup = isDark ? this.change_theme_colors?.get('dark') : this.change_theme_colors?.get('light');

		if (formGroup && ref && formGroup.get(ref)) {
			formGroup.patchValue({
				[ref]: color.newColor,
			});
		}
	}

	changeTheme(event: any): void {
		this.selectedTheme = event.value;
		this.change_select_theme?.setValue(this.selectedTheme);
		this.isLight = this.selectedTheme === 'light' ? true : false;
	}
}
