<app-header
	title="personalize seu aplicativo"
	description="Voltar ao painel"></app-header>
<app-page-layout>
	<div class="background-surface border-radius-8 w-100-p top-- p-0">
		<div class="img p-40 border-radius-8">
			<div class="card-done">
				<img
					src="../../../../assets/done/done.png"
					alt="" />
			</div>
		</div>
	</div>
</app-page-layout>
