import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ActionsStepsComponent } from './app-creation-steps/actions-steps/actions-steps.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MatchaComponentsModule, MatchaGridModule } from 'matcha-components';
import { AcceptTermsCustomizeAppComponent } from './accept-terms-customize-app/accept-terms-customize-app.component';
import { AppCreationStepsComponent } from './app-creation-steps/app-creation-steps.component';
import { DoneSendAppComponent } from './app-creation-steps/done-send-app/done-send-app.component';
import { DoneStepInAppLiteComponent } from './app-creation-steps/done-step-in-app-lite/done-step-in-app-lite.component';
import { DoneStepInAppProComponent } from './app-creation-steps/done-step-in-app-pro/done-step-in-app-pro.component';
import { StepFivePerfilComponent } from './app-creation-steps/step-five-perfil/step-five-perfil.component';
import { StepFourMenuComponent } from './app-creation-steps/step-four-menu/step-four-menu.component';
import { StepOneInitialPageComponent } from './app-creation-steps/step-one-initial-page/step-one-initial-page.component';
import { StepSixStoreComponent } from './app-creation-steps/step-six-store/step-six-store.component';
import { StepThreeLoginScreenComponent } from './app-creation-steps/step-three-login-screen/step-three-login-screen.component';
import { StepTwoOpeningScreenComponent } from './app-creation-steps/step-two-opening-screen/step-two-opening-screen.component';
import { AppInDevComponent } from './app-in-dev/app-in-dev.component';
import { CustomizeAppComponent } from './customize-app/customize-app.component';
import { FormStorageDirective } from './directive/form-storage-directive.directive';
import { ErrorPageComponent } from './error-page/error-page.component';
import { FeaturesRoutingModule } from './features-routing.module';

@NgModule({
	declarations: [
		StepOneInitialPageComponent,
		StepTwoOpeningScreenComponent,
		StepThreeLoginScreenComponent,
		StepFourMenuComponent,
		StepFivePerfilComponent,
		StepSixStoreComponent,
		AppCreationStepsComponent,
		ActionsStepsComponent,
		CustomizeAppComponent,
		AcceptTermsCustomizeAppComponent,
		DoneStepInAppLiteComponent,
		DoneStepInAppProComponent,
		FormStorageDirective,
		DoneSendAppComponent,
		ErrorPageComponent,
		AppInDevComponent,
	],
	imports: [
		CommonModule,
		SharedModule,
		CoreModule,
		RouterModule,
		FormsModule,
		MatIconModule,
		MatchaComponentsModule,
		MatButtonModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatSelectModule,
		MatSlideToggleModule,
		MatStepperModule,
		MatchaGridModule,
		FeaturesRoutingModule,
	],
	exports: [
		AppCreationStepsComponent,
		CustomizeAppComponent,
		AcceptTermsCustomizeAppComponent,
		DoneStepInAppLiteComponent,
		DoneStepInAppProComponent,
		AppInDevComponent,
		FormStorageDirective,
	],
})
export class FeaturesModule {}
