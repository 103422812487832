import { Component, OnInit, effect } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { StepsFormBuilderService } from '../../services/form-builder/steps-form-builder.service';
import { FormStateServiceService } from '../../services/state/form-state-service.service';

@Component({
	selector: 'step-three-login-screen',
	templateUrl: './step-three-login-screen.component.html',
	styleUrls: ['./step-three-login-screen.component.scss'],
})
export class StepThreeLoginScreenComponent implements OnInit {
	public form: FormGroup = new FormGroup({});
	public logoControl: AbstractControl | null = null;
	public notificationIconControl: AbstractControl | null = null;
	public logoImage: string = '';
	public logoNotificationIcon: string = './assets/notification-logo-exemple.png';

	constructor(
		private stepsFormBuilderService: StepsFormBuilderService,
		private stepsStateService: FormStateServiceService
	) {
		effect(() => {
			this.logoImage = this.stepsStateService.getState().login.logo;
			this.logoNotificationIcon =
				this.stepsStateService.getState().login.notification_icon || './assets/notification-logo-exemple.png';
		});
	}

	ngOnInit(): void {
		this.form = this.stepsFormBuilderService.getLoginScreenForm();
		this.logoControl = this.form.get('logo');
		this.notificationIconControl = this.form.get('notification_icon');
	}
}
