<div
	class="p-24 d-flex-column d-flex-center-center gap-24 background-surface border-radius-8"
	appFixDiv>
	<span class="h8 mt-6 color-label">PRÉ-VISUALIZAÇÃO</span>
	<app-cellphone>
		<ng-content></ng-content>
	</app-cellphone>
	<div
		class="d-flex gap-40"
		*ngIf="isActionsVisible">
		<button
			class="button"
			(click)="onClickPrevious($event)">
			<span class="i-matcha-chevron-left"></span>
		</button>
		<button
			class="button"
			(click)="onClickNext($event)">
			<span class="i-matcha-chevron-right"></span>
		</button>
	</div>
</div>
