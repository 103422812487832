<div class="d-flex-column-center-center dashed-border border-color-disabled">
	<div class="mx-48 py-24">
		<div class="d-flex-column-center-center">
			<span class="i-matcha-upload font-size-32"></span>
			<h3 class="title font-weight-700 font-size-20">Enviar imagem</h3>
		</div>
		<div class="d-flex-column">
			<span class="par-s font-size-12 text-center font-weight-500 color-disabled">
				Proporção 1:1,
				<br />
				Formato SVG
				<br />
				Fundo transparente
				<br />
				Ícone
				<span class="color-red">preto (#000000)</span>
			</span>
		</div>
		<input
			#imageFile
			class="w-100-p h-100-p cursor-pointer flow-hidden align-top align-left position-absolute"
			style="opacity: 0"
			type="file"
			id="adv-image-input"
			accept="image/x-png,image/gif,image/jpeg"
			value=""
			(change)="imageChangeEvent($event)" />
	</div>
</div>
