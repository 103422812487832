import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
	providedIn: 'root',
})
export class UploadImageService {
	constructor(private _snackBar: MatSnackBar) {}

	async convertImageToBase64(
		file: File,
		allowedTypes: string,
		maxWidth: number,
		maxHeight: number
	): Promise<{ type: string; name: string; image: string }> {
		return new Promise((resolve, reject) => {
			const img = new Image();

			img.onload = () => {
				if (!this.isFileTypeValid(file, allowedTypes)) {
					this._snackBar.open('Tipo de imagem inválido.', 'X', {
						panelClass: 'snack-error',
					});

					reject(new Error('Tipo de imagem inválido.'));
					return;
				}

				if (!this.isImageSizeValid(img, maxWidth, maxHeight)) {
					this._snackBar.open('Dimensões da imagem excedem o limite permitido.', 'X', {
						panelClass: 'snack-error',
					});

					reject(new Error('Dimensões da imagem excedem o limite permitido.'));
					return;
				}

				const reader = new FileReader();

				reader.onloadend = () => {
					const base64String = reader.result as string;
					const fileInfo = {
						type: file.type,
						name: file.name,
						image: base64String,
					};

					resolve(fileInfo);
				};

				reader.onerror = () => {
					reject(new Error('Erro ao converter a imagem para base64.'));
				};

				if (file) {
					reader.readAsDataURL(file);
				} else {
					reject(new Error('Arquivo de imagem inválido.'));
				}
			};

			img.onerror = () => {
				reject(new Error('Erro ao carregar a imagem.'));
			};

			img.src = URL.createObjectURL(file);
		});
	}

	private isFileTypeValid(file: File, allowedTypes: string): boolean {
		if (!allowedTypes || allowedTypes.trim() === '') {
			return true;
		}

		const allowedTypesArray = allowedTypes.split('/').map((type) => type.trim().toLowerCase());
		const fileType = file.type.toLowerCase();

		return allowedTypesArray.some((type) => fileType.includes(type));
	}

	private isImageSizeValid(img: HTMLImageElement, maxWidth: number, maxHeight: number): boolean {
		return img.width <= maxWidth && img.height <= maxHeight;
	}
}
