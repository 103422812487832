import { Component, OnInit, effect } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { StepsFormBuilderService } from '../../services/form-builder/steps-form-builder.service';
import { FormStateServiceService } from '../../services/state/form-state-service.service';

@Component({
	selector: 'step-two-opening-screen',
	templateUrl: './step-two-opening-screen.component.html',
	styleUrls: ['./step-two-opening-screen.component.scss'],
})
export class StepTwoOpeningScreenComponent implements OnInit {
	public form: FormGroup = new FormGroup({});
	public logoControl: AbstractControl | null = null;
	public backgroundImageControl: AbstractControl | null = null;
	public poweredByColorControl: AbstractControl | null = null;
	public logoImage: string = '';
	public backgroundImage: string = '';
	isLite: boolean = true;

	constructor(
		private stepsFormBuilderService: StepsFormBuilderService,
		private stepsStateService: FormStateServiceService
	) {
		effect(() => {
			this.logoImage = this.stepsStateService.getState().splash.logo;
			this.backgroundImage = this.stepsStateService.getState().splash.background;
		});
	}

	ngOnInit(): void {
		this.form = this.stepsFormBuilderService.getSplashForm();
		this.logoControl = this.form.get('logo');
		this.backgroundImageControl = this.form.get('background');
		this.poweredByColorControl = this.form.get('logo_color');
	}
	onChangeColor(color: { newColor: string }) {
		this.poweredByColorControl!.setValue(color.newColor);
	}
}
