<mat-form-field
	class="w-100-p"
	appearance="outline">
	<mat-label class="color-label d-flex-align-center gap-4">
		{{ label }}
		<span
			*ngIf="isUrl"
			class="i-matcha-info-out"></span>
	</mat-label>
	<input
		class="w-100-p color-label"
		required="{{ isRequired }}"
		maxLength="{{ maxLength || 100 }}"
		matInput
		autocomplete="{{ name }}"
		value="{{ value }}" />
	<mat-hint
		matSuffix
		class="small color-label pr-8">
		{{ valueLength || 0 }} / {{ maxLength || 100 }}
	</mat-hint>
	<mat-error>O campo {{ label }} é obrigatório</mat-error>
</mat-form-field>
