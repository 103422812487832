import { Component, effect } from '@angular/core';
import { FormStateServiceService } from 'src/app/features/services/state/form-state-service.service';
import { isColorDark } from 'src/utils/isColorDark';

@Component({
	selector: 'preview-store',
	templateUrl: './preview-store.component.html',
	styleUrls: ['./preview-store.component.scss'],
})
export class PreviewStoreComponent {
	public logoIcon: string = '../../../../assets/preview-splash/logo-default.png';
	public logoBackground: string = '';
	public backgroundCard: string = '';
	public textColor: string = '';
	public churchName: string = '';
	public text: string = '';

	public primaryColor: string = '';
	public backgroundColor: string = '';
	public theme: 'light' | 'dark' = 'light';
	public luminanceColor: boolean = false;
	public luminanceBgColor: boolean = false;
	public imageIosUse: string = '';

	constructor(private stepsStateService: FormStateServiceService) {
		effect(() => {
			this.logoIcon = this.stepsStateService.getState().store.icon;
			this.logoBackground = this.stepsStateService.getState().store.colors.icon;
			this.backgroundCard = this.stepsStateService.getState().home_screen.colors[this.theme].primary;
			this.textColor = this.stepsStateService.getState().store.colors.text;
			this.churchName = this.stepsStateService.getState().store.name;

			this.theme = this.stepsStateService.getState().home_screen.home.selectTheme as 'light' | 'dark';
			this.primaryColor = this.stepsStateService.getState().home_screen.colors[this.theme].primary;
			this.luminanceColor = isColorDark(this.primaryColor);

			this.backgroundColor = this.stepsStateService.getState().home_screen.colors[this.theme].background;
			this.luminanceBgColor = isColorDark(this.backgroundColor);

			this.imageIosUse = this.luminanceBgColor
				? '../../../../assets/preview-store/classDark.png'
				: '../../../../assets/preview-store/class.png';
		});
	}
}
