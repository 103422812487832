<app-page-layout>
	<app-card-step-title
		title="Tela de abertura"
		description="Crie a tela de abertura do aplicativo. Está será a primeira tela que o seu membro irá ver depois que abrir o aplicativo."></app-card-step-title>
	<div class="d-flex gap-24">
		<div class="w-50-p d-flex-column gap-16">
			<app-preview>
				<preview-splash></preview-splash>
			</app-preview>
		</div>
		<div class="w-100-p d-flex-column gap-16">
			<app-section-page title="">
				<app-card-section
					urlTemplate="http://localhost:4200/assets/templates/step-two-opening-screen/template-opening-screen.png"
					title="Logotipo">
					<upload-image
						[select_image_control]="logoControl"
						[uploadImage]="logoImage"></upload-image>
					<app-card-info
						title="Aqui vão algumas dicas sobre a tela inicial do aplicativo:"
						description="Use um logotipo quadrado ou retangular. Insira o logotipo 
						com fundo transparente para um visual bonito. Confira o protótipo ao lado para ter uma ideia."></app-card-info>
					<span class="underline color-label font-weight-700 font-size-16">
						Precisa de ajuda?
						<a
							href="https://wa.link/ztl37o"
							target="_blank"
							style="text-decoration-line: underline"
							class="color-base">
							Clique aqui!
						</a>
					</span>
				</app-card-section>
				<app-card-section title="Fundo (opcional)">
					<upload-image
						dimensions="2160x3840"
						[select_image_control]="backgroundImageControl"
						[uploadImage]="backgroundImage"
						[type]="'background'"></upload-image>
					<upload-video *ngIf="!isLite"></upload-video>
					<app-card-info
						title="Olha isso daqui é importante!"
						description="Use um fundo simples, sem texto ou muitas informações, para não atrapalhar a leitura do logotipo./*
						Preste atenção ao contraste entre o logotipo e o fundo. Se o logotipo for claro, opte por um fundo escuro para garantir uma boa visibilidade./*						
						Caso não insira uma imagem ou vídeo, será usado o fundo padrão como mostra no protótipo ao lado."></app-card-info>
				</app-card-section>
				<app-card-section title="Logotipo: Powered by inChurch">
					<div class="height-fit">
						<span class="par-p color-label font-weight-500">
							No campo abaixo você poderá trocar a cor do logotipo da inchurch que aparece na parte
							inferior da tela.
						</span>
						<pick-color
							[color]="poweredByColorControl?.value"
							class="pl-24 pt-24"
							openPosition="right"
							(changeColor)="onChangeColor($event)"></pick-color>
					</div>
				</app-card-section>
			</app-section-page>
			<ng-content></ng-content>
		</div>
	</div>
</app-page-layout>
