import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-card-info',
	templateUrl: './card-info.component.html',
	styleUrls: ['./card-info.component.scss'],
})
export class CardInfoComponent implements OnInit {
	@Input() title: string = '';
	@Input() description: string = '';

	newDescription: string[] = [];

	formattedDescription(): string[] {
		this.newDescription = this.description.trim().split('/*');

		this.newDescription = this.newDescription.length ? this.newDescription : [this.description];
		return this.newDescription;
	}

	ngOnInit(): void {}
}
