import { Component, effect } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormStateServiceService } from 'src/app/features/services/state/form-state-service.service';

@Component({
	selector: 'app-cellphone',
	templateUrl: './cellphone.component.html',
	styleUrls: ['./cellphone.component.scss'],
})
export class CellphoneComponent {
	public form: FormGroup = new FormGroup({});
	public colors: any;
	public theme: any;
	public color: string = '';
	constructor(private stepsStateService: FormStateServiceService) {
		effect(() => {
			this.colors = this.stepsStateService.getState().home_screen.colors;
			this.theme = this.stepsStateService.getState().home_screen.home.selectTheme;
			this.color = this.colors[this.theme].background;
		});
	}
}
