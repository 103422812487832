import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { STEPS_VALUES } from '../app-creation-steps/constants';
import { FormStateServiceService } from '../services/state/form-state-service.service';
import { AppState } from '../services/state/state';
import { StepsService } from '../services/steps/steps.service';

@Component({
	selector: 'customize-app',
	templateUrl: './customize-app.component.html',
	styleUrls: ['./customize-app.component.scss'],
})
export class CustomizeAppComponent {
	public continueEdit: boolean = false;

	public inDevApp: boolean = false;
	public stateSteps: AppState;
	private appConfigId: string;

	setPlayVideo() {
		throw new Error('Method not implemented.');
	}
	playVideo() {
		throw new Error('Method not implemented.');
	}

	video = {
		preview: 'https://www.youtube.com/watch?v=_du5gqx8RIE',
		thumbnail: '../../../assets/thumbnail.png',
	};

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private stepsService: StepsService,
		private stepFormStateService: FormStateServiceService
	) {}

	ngOnInit(): void {
		const routeSnapshot = this.activatedRoute.snapshot;

		this.appConfigId = routeSnapshot.params['appConfigId'];
		if (this.appConfigId) {
			this.stepsService.getInitalState(this.appConfigId).subscribe((appconfig: any) => {
				this.inDevApp = appconfig['in_dev'];
				if (!this.inDevApp) {
					const path = STEPS_VALUES.find((step) => step.stage === appconfig['stage'])?.path;
					if (appconfig['stage'] > 0) {
						this.router.navigate([`criar-app/${this.appConfigId}/${path}`]);
					}
				}
			});
		}
	}

	onNavigateAcceptTerms() {
		this.router.navigate([`/aceitar_termos/${this.appConfigId}`]);
	}
}
