import { Injectable, signal } from '@angular/core';
import { AppState } from '../state/state';
import { INITIAL_VALUES } from './constants';

@Injectable({
	providedIn: 'root',
})
export class FormStateServiceService {
	#state = signal<AppState>(INITIAL_VALUES);

	constructor() {}
	setState(state: AppState) {
		return this.#state.set(state);
	}

	private recursivelyCheckAndUpdate(
		currentValue: Record<string, Partial<AppState>>,
		newState: Record<string, Partial<AppState>>
	): Record<string, Partial<AppState>> {
		const updatedState: Record<string, Partial<AppState>> = {};

		Object.keys(newState).forEach((key) => {
			if (currentValue.hasOwnProperty(key) && typeof newState[key] === 'object' && currentValue[key]) {
				const nestedUpdatedState = this.recursivelyCheckAndUpdate(
					currentValue[key] as Record<string, Partial<AppState>>,
					newState[key] as Record<string, Partial<AppState>>
				);
				if (Object.keys(nestedUpdatedState).length > 0) {
					updatedState[key] = nestedUpdatedState;
				}
			} else if (currentValue[key] !== newState[key]) {
				updatedState[key] = newState[key];
			}
		});

		return updatedState;
	}
	getState() {
		return this.#state();
	}
}
