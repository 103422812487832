<app-header
	title="personalize seu aplicativo"
	description="Voltar ao painel"></app-header>
<app-page-layout>
	<div class="d-flex gap-24 top--">
		<div class="w-50-p d-flex-column gap-16">
			<app-preview>
				<div class="preview">
					<img
						src="../../../assets/cellphone-image.png"
						alt="" />
				</div>
			</app-preview>
		</div>
		<div class="w-100-p d-flex-column gap-16">
			<app-card-section>
				<div class="d-flex-column gap-40 padding-card">
					<h1 class="font-size-32 max-width-480 text-ajustsments">
						Vamos personalizar
						<br />
						seu aplicativo {{ isPro ? 'PRO' : 'LITE' }}!
					</h1>

					<div>
						<p class="par-m font-weight-500 font-size-16 color-label order-0 description">
							Antes de começar, vamos alinhar as expectativas, certo? Após finalizar a personalização,
							enviaremos suas alterações para nossa equipe de desenvolvimento. Eles vão trabalhar com
							muito carinho para desenvolver e publicar seu aplicativo nas lojas do Google e da Apple.
							Porém, é importante lembrar que o aplicativo não estará disponível imediatamente.
						</p>
						<p class="par-m font-weight-500 font-size-16 color-label order-0 description">
							<b class="color-text">
								Normalmente, precisamos de aproximadamente 25 dias para fazer a implementação. Caso
								surja algum problema, nossa equipe de suporte entrará em contato com você, e isso pode
								afetar o prazo de entrega.
							</b>
						</p>
						<p class="par-m font-weight-500 font-size-16 color-label order-0 description">
							Ao clicar no botão abaixo, você confirma que leu e concorda com os termos acima.
						</p>
					</div>
					<div class="d-flex gap-16">
						<button
							class="matcha-button-md color-label button-previous cursor-pointer"
							mat-raised-button
							(click)="onNavigatePersonalize()">
							Voltar
						</button>
						<button
							(click)="onNavigateCreateApp()"
							class="matcha-button-md button-advance"
							mat-stroked-button>
							Concordar e avançar
						</button>
					</div>
				</div>
			</app-card-section>
		</div>
	</div>
</app-page-layout>
