import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject, debounceTime, takeUntil } from 'rxjs';
import { FormStateServiceService } from '../services/state/form-state-service.service';

@Directive({
	selector: 'form[formGroup][storageStepsForm]',
})
export class FormStorageDirective implements OnInit, OnDestroy {
	@Input()
	formGroup: FormGroup = new FormGroup({});
	@Input()
	storageStepsForm: string = '';

	private destroy$ = new Subject<void>();

	constructor(private formStateService: FormStateServiceService) {}
	ngOnInit(): void {
		this.updateFormValue();
		this.listenUpdateValue();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private updateFormValue(): void {
		const storageValue = JSON.parse(localStorage.getItem(this.storageStepsForm)!);
		const state = this.formStateService.getState();

		if (storageValue) {
			this.formStateService.setState(storageValue);
			this.formGroup.patchValue(storageValue);
		} else if (state) {
			this.formGroup.patchValue(state);
		}
	}

	private listenUpdateValue(): void {
		this.formGroup.valueChanges.pipe(debounceTime(300), takeUntil(this.destroy$)).subscribe((value) => {
			this.formStateService.setState(value);
			localStorage.setItem(this.storageStepsForm, JSON.stringify(value));
		});
	}
}
