import { Component } from '@angular/core';
import { Menus } from './step-five-perfil';

@Component({
	selector: 'step-five-perfil',
	templateUrl: './step-five-perfil.component.html',
	styleUrls: ['./step-five-perfil.component.scss'],
})
export class StepFivePerfilComponent {
	public menus: Menus = {
		profile: {
			type: 'list',
			items: [
				{
					title: 'Home',
					action: 'https://',
					image: 'default/{image_name}',
				},
			],
		},
	};
}
