import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-card-tutorial',
	templateUrl: './card-tutorial.component.html',
	styleUrls: ['./card-tutorial.component.scss'],
})
export class CardTutorialComponent {
	@Input() title: string = '';
	@Output() handleClickCloseTutorial = new EventEmitter<void>();
	@Input() description: string = '';
	@Input() isCloseTutorial: boolean = true;

	onClickCloseTutorial() {
		return this.handleClickCloseTutorial.emit();
	}
}
