import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
@Component({
	selector: 'select-input-icon',
	templateUrl: './select-input-icon.component.html',
	styleUrls: ['./select-input-icon.component.scss'],
})
export class SelectInputIconComponent {
	showDropdown = false;
	iconList = [
		'i-matcha-church',
		'i-matcha-bonus',
		'i-matcha-leadership',
		'i-matcha-lightbulb',
		'i-matcha-info',
		'i-matcha-business',
		'i-matcha-case',
		'i-matcha-news',
		'i-matcha-store',
		'i-matcha-gallery',
	]; // Substitua pelos seus ícones padrão
	selectedIcon: string | null = null;

	constructor(private dialog: MatDialog) {}

	openDropdown() {
		this.showDropdown = true;
	}

	closeDropdown() {
		this.showDropdown = false;
	}

	selectIcon(icon: string) {
		this.selectedIcon = icon;
		this.closeDropdown();
	}

	uploadIcon(event: any) {
		// Lógica para processar o upload do ícone em SVG
		const file = event.target.files[0];
		// Processar o arquivo e definir this.selectedIcon
		this.closeDropdown();
	}
}
