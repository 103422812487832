<mat-radio-group
	aria-label="Select an option"
	(change)="changeTheme($event)">
	<mat-radio-button
		value="light"
		[checked]="selectedTheme === 'light'">
		Tema Claro
	</mat-radio-button>
	<mat-radio-button
		value="dark"
		[checked]="selectedTheme === 'dark'">
		Tema Escuro
	</mat-radio-button>
</mat-radio-group>

<div class="grid-2 p-24 gap-16 background-bg-alpha border-radius-8 h-auto">
	<ng-container *ngIf="isLight">
		<div>
			<h6 class="title text-bold">
				<span class="title-bullet background-surface-inverse h-24 w-8"></span>
				Tema Claro
			</h6>

			<div *ngFor="let card of cards">
				<card-color
					*ngIf="!card.isDark && !card.isAdvanced"
					name="{{ card.name }}"
					[isDark]="card.isDark">
					<pick-color
						color="{{ card.color }}"
						openPosition="right"
						[disable]="selectedTheme === 'dark'"
						(changeColor)="onColorThemeChange($event, card.isDark, card.ref)"></pick-color>
				</card-color>
				<div class="my-16"></div>
				<card-color
					*ngIf="!card.isDark && isShowAdvancedOptionsTheme && card.isAdvanced"
					name="{{ card.name }}"
					[isDark]="card.isDark">
					<pick-color
						color="{{ card.color }}"
						openPosition="right"
						[disable]="selectedTheme === 'dark'"
						(changeColor)="onColorThemeChange($event, card.isDark, card.ref)"></pick-color>
				</card-color>
			</div>
		</div>
	</ng-container>

	<ng-container *ngIf="!isLight">
		<div>
			<h6 class="title text-bold">
				<span class="title-bullet background-surface-inverse h-24 w-8"></span>
				Tema Escuro
			</h6>

			<div *ngFor="let card of cards">
				<card-color
					*ngIf="card.isDark && !card.isAdvanced"
					name="{{ card.name }}"
					[isDark]="card.isDark">
					<pick-color
						[color]="card.color"
						openPosition="left"
						[disable]="selectedTheme === 'light'"
						(changeColor)="onColorThemeChange($event, card.isDark, card.ref)"></pick-color>
				</card-color>
				<div class="my-16"></div>
				<card-color
					*ngIf="card.isDark && isShowAdvancedOptionsTheme && card.isAdvanced"
					name="{{ card.name }}"
					[isDark]="card.isDark">
					<pick-color
						color="{{ card.color }}"
						openPosition="left"
						[disable]="selectedTheme === 'light'"
						(changeColor)="onColorThemeChange($event, card.isDark, card.ref)"></pick-color>
				</card-color>
			</div>
		</div>
	</ng-container>
</div>
