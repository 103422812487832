<div class="wrapper">
	<div>
		<img
			class="logo"
			[src]="logoIcon"
			alt="" />
	</div>
	<div class="inputs">
		<img
			src="../../../../assets/preview-login/login.png"
			alt="" />
		<img
			src="../../../../assets/preview-login/password.png"
			alt="" />
		<div class="button">
			<button
				class="matcha-button-pill matcha-button-xs button-enter"
				mat-flat-button
				[style.background]="primaryColor"
				[ngClass]="luminanceButtonColor ? 'color-fg-inverse' : 'color-fg'">
				Entrar
			</button>
		</div>
		<span
			class="par-s pt-16"
			[ngClass]="luminanceBgColor ? 'color-fg-inverse' : 'color-fg'">
			Esqueci a senha
		</span>
		<div class="inputs pt-20">
			<button
				class="matcha-button-pill matcha-button-xs button-enter color-fg-inverse"
				mat-flat-button
				[style.background]="'#3A5998'">
				<span class="i-matcha-facebook fs-16 color-fg-inverse"></span>
				Entrar com o Facebook
			</button>
			<button
				class="matcha-button-pill matcha-button-xs button-enter color-fg-inverse"
				mat-flat-button
				[style.background]="'#000000'">
				<span class="i-matcha-apple fs-16 color-fg-inverse"></span>
				Continuar com a Apple
			</button>
			<small
				class="pt-16"
				[ngClass]="luminanceBgColor ? 'color-fg-inverse' : 'color-fg'">
				Ainda não tem cadastro? Cadastre-se
			</small>
		</div>
	</div>
</div>
