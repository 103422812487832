<app-page-layout>
	<app-card-step-title
		title="Menus"
		description='Configure o estilo e a navegação dos menus "Mais" e "Rodapé" nesta etapa.'></app-card-step-title>
	<div class="d-flex gap-24">
		<div class="w-50-p d-flex-column gap-16">
			<app-preview>
				<div>olá</div>
			</app-preview>
		</div>
		<div class="w-100-p d-flex-column gap-16">
			<app-section-page title="">
				<app-card-section title="Formato do Menu Mais">
					<span class="par-p pl-24 color-label font-weight-500">
						Escolha um formato para o menu principal do aplicativo:
					</span>
					<div class="d-flex gap-16">
						<button
							class="matcha-button-lg bg-transparent"
							style="width: 100%; height: 56px"
							mat-stroked-button
							color="primary"
							type="button"
							tabindex="-1">
							<div class="d-flex-center-center gap-16">
								<span class="i-matcha-text-align-justify"></span>
								<h5 class="title font-weight-500 font-size-16 pt-12">Lista</h5>
							</div>
						</button>
						<button
							class="matcha-button-lg bg-transparent"
							style="width: 100%; height: 56px"
							mat-stroked-button
							color="primary"
							type="button"
							tabindex="-1">
							<div class="d-flex-center-center gap-16">
								<span class="i-matcha-dashboard"></span>
								<h5 class="title font-weight-500 font-size-16 pt-12">Bloco</h5>
							</div>
						</button>
					</div>
				</app-card-section>
				<app-card-section title="Estilo de ícone">
					<span class="par-p pl-24 color-label font-weight-500">
						Escolha um estilo de ícone para os menus do aplicativo:
					</span>
					<div class="d-flex gap-16">
						<button
							class="matcha-button-lg bg-transparent"
							style="width: 100%; height: 56px"
							mat-stroked-button
							color="primary"
							type="button">
							<div class="d-flex-center-center gap-16">
								<span class="i-matcha-favorite"></span>
								<h5 class="title font-weight-500 font-size-16 pt-12">Ícones preenchidos</h5>
							</div>
						</button>
						<button
							class="matcha-button-lg bg-transparent"
							style="width: 100%; height: 56px"
							mat-stroked-button
							color="primary"
							type="button"
							tabindex="-1">
							<div class="d-flex-center-center gap-16">
								<span class="i-matcha-favorite-out"></span>
								<h5 class="title font-weight-500 font-size-16 pt-12">Ícones vazados</h5>
							</div>
						</button>
					</div>
				</app-card-section>
				<app-card-section title="Ordenação dos itens">
					<span class="par-p pl-24 color-label font-weight-500">
						Configure os itens a serem exibidos no aplicativo e ordene-os nos menus "Rodapé" e "Mais". O
						menu "Rodapé" terá apenas os itens essenciais exibidos em todas as telas, enquanto os demais
						itens estarão no menu "Mais".
					</span>
					<div class="p-24 d-flex-column gap-16 background-bg-alpha border-radius-8 h-auto">
						<div class="d-flex-align-center gap-8">
							<mat-slide-toggle hideIcon></mat-slide-toggle>
							<span>Use esse botão para ativar e desativar um menu</span>
							<span class="material-symbols-outlined"></span>
							<span>Arraste para mudar a ordem.</span>
						</div>
						<div>
							<h6 class="title text-bold">
								<span
									class="d-flex-align-center title-bullet background-surface-inverse h-24 w-8"></span>
								Rodapé
								<span class="color-label ml-8">
									Precisa conter até 5 itens, incluindo a Home e Menu Mais
								</span>
							</h6>
						</div>

						<menu-list-card *ngFor="let item of menus.profile.items">
							<div class="d-flex-align-center">
								<mat-slide-toggle hideIcon></mat-slide-toggle>
								<div class="gap-4 mt-24 max-width-input">
									<app-input
										label="Nomenclatura de {{ item.title }}"
										placeholder="{{ item.title }}"
										name="{{ item.title }}"
										valueLength="0"
										maxLength="24"></app-input>
								</div>
								<div class="gap-4 mt-24 max-width-input">
									<app-input
										*ngIf="item.action.startsWith('https://')"
										[isUrl]="true"
										[isRequired]="false"
										label="url"
										placeholder="Url"
										name="Url"
										valueLength="0"
										maxLength="24"></app-input>
								</div>

								<select-input-icon></select-input-icon>
							</div>
						</menu-list-card>

						<div>
							<h6 class="title text-bold">
								<span
									class="d-flex-align-center title-bullet background-surface-inverse h-24 w-8"></span>
								Menu Mais
							</h6>
						</div>
						<menu-list-card *ngFor="let item of menus.profile.items">
							<div class="d-flex-align-center">
								<mat-slide-toggle hideIcon></mat-slide-toggle>
								<div class="gap-4 mt-24 max-width-input">
									<app-input
										label="Nomenclatura de {{ item.title }}"
										placeholder="{{ item.title }}"
										name="{{ item.title }}"
										valueLength="0"
										maxLength="24"></app-input>
								</div>
								<div class="gap-4 mt-24 max-width-input">
									<app-input
										*ngIf="item.action.startsWith('https://')"
										[isUrl]="true"
										[isRequired]="false"
										label="url"
										placeholder="Url"
										name="Url"
										valueLength="0"
										maxLength="24"></app-input>
								</div>

								<select-input-icon></select-input-icon>
							</div>
						</menu-list-card>
						<button
							class="matcha-button-lg bg-transparent"
							style="width: 100%; height: 56px"
							mat-stroked-button
							color="primary"
							type="button"
							tabindex="-1">
							<div class="d-flex-center-center gap-16">
								<span class="i-matcha-action_plus"></span>
								<h5 class="title font-weight-500 font-size-16 pt-12">Adicionar novo item</h5>
							</div>
						</button>
						<span class="underline color-label font-weight-700 font-size-16">
							Precisa de ajuda?
							<a
								href="https://wa.link/ztl37o"
								target="_blank"
								style="text-decoration-line: underline"
								class="color-base">
								Clique aqui!
							</a>
						</span>
					</div>
				</app-card-section>
			</app-section-page>
			<ng-content></ng-content>
		</div>
	</div>
</app-page-layout>
