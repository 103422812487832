<app-page-layout>
	<app-card-step-title
		title="Tela de Login"
		description="Envie o logotipo que será exibido na tela de login, bem como o logo que aparecerá na aba de notificações do celular."></app-card-step-title>
	<div class="d-flex gap-24">
		<div class="w-50-p d-flex-column gap-16">
			<app-preview>
				<preview-login></preview-login>
			</app-preview>
		</div>
		<div class="w-100-p d-flex-column gap-16">
			<app-section-page title="">
				<app-card-section
					urlTemplate="http://localhost:4200/assets/templates/step-two-opening-screen/template-opening-screen.png"
					title="Logotipo da tela de login">
					<span class="par-p pl-24 color-label font-weight-500">
						Insira o logotipo da sua igreja abaixo. Ele será exibido na tela de login, como mostrado no
						protótipo ao lado.
					</span>
					<upload-image
						dimensions="700x300"
						[select_image_control]="logoControl"
						[uploadImage]="logoImage"></upload-image>
					<app-card-info
						title="Aqui vai uma dica sobre essa tela:"
						description="Uma vez que a área de notificação é muito pequena, não enviar ícones com textos. Insira com fundo transparente; /*
            O ícone somente irá aparecer se estiver na cor branca;/*            
            Se o cliente não inserir um ícone, será colocado um padrão."></app-card-info>
					<span class="underline color-label font-weight-700 font-size-16">
						Precisa de ajuda?
						<a
							href="https://wa.link/ztl37o"
							target="_blank"
							style="text-decoration-line: underline"
							class="color-base">
							Clique aqui!
						</a>
					</span>
				</app-card-section>
				<app-card-section
					urlTemplate="http://localhost:4200/assets/templates/step-two-opening-screen/template-opening-screen.png"
					title="Logo de notificação">
					<span class="par-p pl-24 color-label font-weight-500">
						O logo aparecerá junto com as notificações enviadas pela igreja aos membros. Devido ao tamanho
						reduzido e à variação de cores nos dispositivos Android, o ícone deve ser inserido apenas na
						forma de símbolo e na cor branca! Exemplo:
					</span>

					<div class="notification-preview">
						<div class="notification-header mb-8">
							<img
								[src]="logoNotificationIcon"
								width="16"
								height="16"
								class="logo mr-4" />
							<span class="app-name font-size-12 font-weight-700">SEU APP</span>
						</div>

						<div class="notification-msg">
							<h4 class="title font-size-14"><b>Ficou sabendo da última?</b></h4>
							<p class="text font-size-12">Tem nova postagem em notícia</p>
						</div>
					</div>

					<upload-image
						dimensions="96x96"
						[select_image_control]="notificationIconControl"></upload-image>
					<app-card-info
						title="Aqui vai uma dica sobre essa tela:"
						description="Use um logotipo mais quadrado/horizontal, com o nome abaixo do símbolo. Insira o logotipo com fundo transparente para um visual bonito. Confira o protótipo ao lado para ter uma ideia.
            
            "></app-card-info>
					<span class="underline color-label font-weight-700 font-size-16">
						Precisa de ajuda?
						<a
							href="https://wa.link/ztl37o"
							target="_blank"
							style="text-decoration-line: underline"
							class="color-base">
							Clique aqui!
						</a>
					</span>
				</app-card-section>
			</app-section-page>
			<ng-content></ng-content>
		</div>
	</div>
</app-page-layout>
