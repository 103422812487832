import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable({
	providedIn: 'root',
})
export class StepsFormBuilderService {
	form: FormGroup = new FormGroup({});
	constructor(private fb: FormBuilder) {}

	createStepsLiteForm() {
		this.form = this.fb.group({
			home_screen: this.fb.group({
				home: this.fb.group({
					logo: [''],
					selectTheme: 'dark',
				}),
				colors: this.fb.group({
					light: this.fb.group({
						primary: [''],
						background: ['#FFFFFF'],
						surface: [''],
						accent: [''],
						on_accent: [''],
					}),
					dark: this.fb.group({
						primary: [''],
						background: ['#1F1F1F'],
						surface: [''],
						accent: [''],
						on_accent: [''],
					}),
				}),
			}),
			splash: this.fb.group({
				logo: [''],
				background: [''],
				logo_color: [''],
			}),
			login: this.fb.group({
				logo: [''],
				notification_icon: [''],
			}),
			store: this.fb.group({
				name: [''],
				cellphone_name: [''],
				icon: [''],
				images: [],
				colors: this.fb.group({
					background: [''],
					text: [''],
					icon: [''],
				}),
			}),
		});
		return this.form;
	}
	getForm(): FormGroup {
		return this.form;
	}
	getHomeScreenForm(): FormGroup {
		return this.form.get('home_screen') as FormGroup;
	}

	getSplashForm(): FormGroup {
		return this.form.get('splash') as FormGroup;
	}

	getLoginScreenForm(): FormGroup {
		return this.form.get('login') as FormGroup;
	}

	getStoreForm(): FormGroup {
		return this.form.get('store') as FormGroup;
	}
}
