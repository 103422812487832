import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'accept-terms-customize-app',
	templateUrl: './accept-terms-customize-app.component.html',
	styleUrls: ['./accept-terms-customize-app.component.scss'],
})
export class AcceptTermsCustomizeAppComponent implements OnInit {
	private appConfigId: number;

	isPro: boolean = false;

	constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

	ngOnInit(): void {
		const routeSnapshot = this.activatedRoute.snapshot;

		this.appConfigId = routeSnapshot.params['appConfigId'];
	}

	onNavigatePersonalize() {
		this.router.navigate([`/personalize/${this.appConfigId}`]);
	}
	onNavigateCreateApp() {
		this.router.navigate([`/criar-app/${this.appConfigId}`]);
	}
}
