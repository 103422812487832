import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
})
export class InputComponent {
	@Input() name: string = '';
	@Input() label: string = '';
	@Input() placeholder: string = '';
	@Input() value: string = '';
	@Input() maxLength: string = '20';
	@Input() valueLength: string = '3';
	@Input() isUrl: boolean = false;
	@Input() isRequired: boolean = false;
}
