<mat-form-field
	appearance="outline"
	class="w-80 input-pick d-flex-center-center scale-input-field">
	<input
		class="border-radius-4 w-24"
		#inputEle
		matInput
		[style.color]="color"
		value="{{ color }}"
		[(colorPicker)]="color"
		[style.background-color]="color"
		[cpPosition]="openPosition"
		readonly
		[disabled]="disable"
		(colorPickerChange)="setColor($event)" />
	<mat-hint
		matSuffix
		class="small color-grey-400 pr-8">
		<span class="i-matcha-chevron-down icon"></span>
	</mat-hint>
</mat-form-field>
