<header class="w-100-p d-flex-center-center primary-bg">
	<div class="w-80-p h-128 d-flex-align-center d-flex-space-between">
		<div>
			<svg
				width="120"
				height="24"
				viewBox="0 0 120 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M75.1621 22.8965V14.8459C75.1222 13.4466 75.386 12.0545 75.9371 10.7553C76.3912 9.7217 77.1091 8.81057 78.0268 8.10344C78.9011 7.46115 79.9046 6.99386 80.9761 6.73004C82.1111 6.43766 83.2824 6.29002 84.4588 6.29102H85.1208V10.7405H83.5959C82.3116 10.7405 81.3609 11.0312 80.7439 11.6126C80.1268 12.194 79.8131 13.1096 79.8026 14.3594V22.9113L75.1621 22.8965Z"
					fill="#FAFBFD" />
				<path
					d="M73.9306 6.29102V16.0294C73.9306 18.9958 73.1127 20.9318 71.477 21.8375C69.8522 22.7491 67.9949 23.224 66.1055 23.2109C64.2636 23.1468 62.47 22.6361 60.8971 21.7277C59.3283 20.8102 58.5439 18.9157 58.5439 16.0443V6.29102H63.1875V16.9045C63.1875 17.9041 63.5013 18.6309 64.1288 19.0907C64.7315 19.5427 65.4805 19.7847 66.2498 19.7759C67.0291 19.7815 67.7879 19.5403 68.4053 19.0907C69.0328 18.6309 69.3654 17.9041 69.3654 16.9045V8.4149C69.3588 7.86149 69.5844 7.32815 69.9929 6.93174C70.2021 6.72534 70.455 6.56292 70.735 6.45508C71.0151 6.34724 71.316 6.29639 71.6182 6.30585L73.9306 6.29102Z"
					fill="#FAFBFD" />
				<path
					d="M99.015 17.7801H102.331C101.846 19.5283 100.917 20.8601 99.5453 21.7758C98.1731 22.7349 96.4485 23.2134 94.3714 23.2115C91.8613 23.2115 89.8941 22.4412 88.4696 20.9007C87.0556 19.4017 86.3486 17.2581 86.3486 14.4697C86.3486 11.8099 87.0337 9.7335 88.4037 8.24045C89.8177 6.70192 91.785 5.93167 94.3055 5.92969C96.9599 5.92969 99.016 6.67918 100.474 8.17815C101.28 9.11206 101.868 10.1968 102.2 11.361H97.556C97.3862 11.019 97.163 10.7031 96.894 10.4237C96.3167 9.83039 95.5009 9.55156 94.4404 9.55156C94.0006 9.53589 93.5621 9.60513 93.1516 9.75508C92.741 9.90502 92.3671 10.1325 92.0527 10.4237C91.2558 11.2582 90.8573 12.6316 90.8573 14.5439C90.8573 16.4562 91.2558 17.8088 92.0527 18.6018C92.6269 19.2692 93.4866 19.6014 94.6381 19.6014C95.3088 19.6207 95.9729 19.4702 96.5614 19.1654C96.6991 19.1199 96.8237 19.0447 96.9254 18.9459L97.2925 18.6018C97.4451 18.4142 97.6234 18.2466 97.8227 18.1035C98.1683 17.8679 98.5899 17.7536 99.015 17.7801Z"
					fill="#FAFBFD" />
				<path
					d="M36.3265 17.7801H39.6429C39.1556 19.5283 38.2269 20.8601 36.8568 21.7758C35.4867 22.7349 33.7631 23.2134 31.6861 23.2115C29.1635 23.2115 27.1952 22.4412 25.7812 20.9007C24.3651 19.4017 23.6581 17.2581 23.6602 14.4697C23.6602 11.8099 24.3452 9.7335 25.7153 8.24045C27.1293 6.70192 29.0965 5.93167 31.617 5.92969C34.2714 5.92969 36.3276 6.67918 37.7855 8.17815C38.5919 9.11206 39.18 10.1968 39.5112 11.361H34.8676C34.6988 11.0184 34.4756 10.7023 34.2055 10.4237C33.6303 9.84226 32.8125 9.55156 31.752 9.55156C31.3122 9.53606 30.8737 9.60539 30.4632 9.75533C30.0527 9.90526 29.6788 10.1327 29.3643 10.4237C28.5673 11.2503 28.1688 12.6197 28.1688 14.532C28.1688 16.4443 28.5673 17.7969 29.3643 18.5899C29.9395 19.2583 30.8013 19.5916 31.9496 19.5896C32.6204 19.6089 33.2844 19.4583 33.8729 19.1535C34.0106 19.108 34.1352 19.0329 34.2369 18.934L34.604 18.5899C34.7566 18.4023 34.9349 18.2347 35.1343 18.0916C35.4815 17.8602 35.9029 17.7502 36.3265 17.7801Z"
					fill="#FAFBFD" />
				<path
					d="M22.4314 22.8974H20.1096C19.8106 22.9117 19.5116 22.8668 19.2321 22.7654C18.9525 22.664 18.6984 22.5085 18.4863 22.3087C18.2741 22.1089 18.1085 21.8694 18.0001 21.6055C17.8917 21.3416 17.8429 21.0592 17.8568 20.7764V12.2868C17.8568 11.2872 17.5431 10.5604 16.8936 10.1007C16.2762 9.65107 15.5174 9.40984 14.7381 9.41544C13.9688 9.40666 13.2198 9.64863 12.6171 10.1007C11.9896 10.5604 11.6758 11.2872 11.6758 12.2868V22.8974H7.03223V13.1589C7.03223 10.3291 7.81766 8.43556 9.38854 7.47843C10.9441 6.52629 12.7421 5.98737 14.5938 5.91815C16.4918 5.90707 18.3543 6.40488 19.9653 7.35385C21.5989 8.31296 22.4168 10.248 22.4189 13.1589L22.4314 22.8974Z"
					fill="#FAFBFD" />
				<path
					d="M114.635 10.9754C114.335 10.5581 113.928 10.2192 113.453 9.99002C112.977 9.76083 112.448 9.64879 111.915 9.66426C111.368 9.63634 110.822 9.73557 110.325 9.95327C109.827 10.171 109.394 10.5005 109.063 10.9131C108.41 11.7476 108.084 12.8916 108.084 14.3451V22.897H103.56V1.42383H105.812C106.408 1.42382 106.98 1.64689 107.403 2.04431C107.826 2.44172 108.065 2.98115 108.068 3.54475V8.4896C108.429 7.89548 108.916 7.3781 109.498 6.9698C110.08 6.56149 110.745 6.27103 111.451 6.11654C112.836 5.7798 114.3 5.86704 115.63 6.36571C116.877 6.87434 117.954 7.69475 118.745 8.73877C119.582 9.82246 120 11.3205 120 13.2327V22.9089H115.564V14.3065C115.568 13.7303 115.512 13.155 115.398 12.589C115.265 12.0067 115.006 11.457 114.635 10.9754Z"
					fill="#FAFBFD" />
				<path
					d="M51.9467 10.9754C51.6464 10.5612 51.2407 10.2251 50.767 9.99808C50.2933 9.77103 49.7667 9.66031 49.2359 9.67612C48.6888 9.64847 48.1428 9.74781 47.6457 9.96549C47.1486 10.1832 46.7153 10.5126 46.3838 10.9249C45.7228 11.7575 45.3913 12.9015 45.3892 14.357V22.9089H40.8711V1.42383H43.127C43.7226 1.4246 44.2937 1.64802 44.7157 2.04535C45.1378 2.44268 45.3765 2.98167 45.3798 3.54475V8.4896C45.7407 7.89548 46.2274 7.3781 46.8093 6.9698C47.3913 6.56149 48.056 6.27103 48.7621 6.11654C50.148 5.7798 51.6111 5.86704 52.9413 6.36571C54.1896 6.87427 55.2677 7.69464 56.0601 8.73877C56.8968 9.82246 57.3151 11.3205 57.3151 13.2327V22.9089H52.8754V14.3065C52.8795 13.7303 52.8238 13.155 52.7092 12.589C52.577 12.0067 52.3172 11.457 51.9467 10.9754Z"
					fill="#FAFBFD" />
				<path
					d="M0.636901 6.29156H3.02459C3.32068 6.28913 3.61423 6.34332 3.8876 6.45088C4.16096 6.55844 4.40851 6.71714 4.61534 6.91746C4.82644 7.10922 4.99401 7.33962 5.10783 7.59461C5.22165 7.84961 5.27933 8.12385 5.27737 8.40062V22.8822H0.636901V6.29156ZM5.80448 2.73198C5.80696 3.27529 5.63879 3.80707 5.32126 4.25991C5.00373 4.71276 4.55115 5.06629 4.02087 5.27569C3.49059 5.4851 2.9065 5.54096 2.3426 5.43619C1.77871 5.33142 1.26041 5.07074 0.853392 4.68719C0.446373 4.30364 0.168958 3.81448 0.0563075 3.28171C-0.0563429 2.74894 0.000841263 2.19654 0.220614 1.69453C0.440387 1.19252 0.812852 0.763489 1.29081 0.461821C1.76876 0.160153 2.33069 -0.000575785 2.90537 6.49963e-06C3.67151 -0.00157152 4.40703 0.28425 4.95054 0.794758C5.49405 1.30527 5.80116 1.99876 5.80448 2.72309V2.73198Z"
					fill="#FAFBFD" />
			</svg>
		</div>
		<div class="gap-2">
			<div
				data-testid="title"
				class="text-uppercase font-size-24 font-weight-400 line-height-28 color-surface text-right">
				{{ title }}
			</div>
			<a
				href="https://admin.inchurch.com.br/"
				data-testid="description"
				class="par-m font-weight-500 color-accent font-size-16 line-height-20 cursor-pointer text-right decoration-none">
				{{ description }}
			</a>
		</div>
	</div>
</header>
