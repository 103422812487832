<app-header
	title="personalize seu aplicativo"
	description="Voltar ao painel"></app-header>
<app-page-layout>
	<div class="background-surface border-radius-8 top-- w-100-p p-0">
		<div class="img p-40 border-radius-8">
			<div class="d-flex-space-between gap-40 ml-64 my-40">
				<div class="d-flex gap-40">
					<div class="d-flex-column gap-28">
						<h1 class="font-size-32 max-width-480 text-ajustsments">
							<ng-container *ngIf="!inDevApp">Crie o seu site/app personalizado em minutos!</ng-container>
							<ng-container *ngIf="inDevApp">Seu app já está em desenvolvimento!</ng-container>
						</h1>

						<span class="par-m font-weight-500 font-size-16 color-label order-0">
							Deixe sua marca na web!

							<b class="color-text">
								Transmita a mensagem
								<br />
								e os valores da sua igreja de forma única e impactante.
								<br />
							</b>
							Vamos iniciar essa jornada emocionante juntos?
							<br />
							Comece agora mesmo!
						</span>

						<div class="grid-md-2 gap-12 order-3">
							<button
								*ngIf="continueEdit"
								matcha-button
								pill="true"
								mat-raised-button
								class="button-customize matcha-button-md text-bold matcha-button-pill">
								Continuar App
							</button>
							<button
								*ngIf="!inDevApp"
								mat-raised-button
								class="button-customize matcha-button-md text-bold matcha-button-pill"
								(click)="onNavigateAcceptTerms()">
								Personalizar App
							</button>
						</div>
					</div>
				</div>

				<div class="w-50-p d-flex border-radius-8 mr-64">
					<div
						(click)="playVideo()"
						class="matcha-card aspect-ratio-16x9 w-100-p d-flex-center-center d-flex-column"
						[ngStyle]="{
							background: 'url(' + video.thumbnail + ') no-repeat center center / cover',
							transition: 'all 100ms linear'
						}">
						<span
							class="d-flex d-flex-center-center i-matcha-play cursor-pointer matcha-color-text-inverse i-size-lg w-52 h-52 matcha-background-accent border-radius-circle bShadow-slide"
							(click)="setPlayVideo()"></span>
					</div>
				</div>
			</div>
		</div>
	</div>
</app-page-layout>
