import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'card-color',
	templateUrl: './card-color.component.html',
	styleUrls: ['./card-color.component.scss'],
})
export class CardColorComponent implements OnInit {
	@Input()
	name: string = '';
	@Input()
	isDark: boolean = false;
	@Input()
	advanced: boolean = true;

	constructor() {}

	ngOnInit(): void {}
}
