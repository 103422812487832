import { Routes } from '@angular/router';
import { authGuard } from './auth.guard';
export const AppRoutes: Routes = [
	{
		path: 'personalize/:appConfigId',
		loadChildren: () => import('./features/features.module').then((m) => m.FeaturesModule),
		canActivate: [authGuard],
	},
	{
		path: '',
		redirectTo: 'personalize',
		pathMatch: 'full',
	},
];
