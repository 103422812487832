export function isColorDark(hexColor: string) {
	if (!hexColor) {
		return false;
	}

	if (!/^#([0-9A-F]{3}){1,2}$/i.test(hexColor)) {
		throw new Error('Formato de cor inválido');
	}

	let r, g, b;
	if (hexColor.length === 4) {
		r = parseInt(hexColor[1] + hexColor[1], 16);
		g = parseInt(hexColor[2] + hexColor[2], 16);
		b = parseInt(hexColor[3] + hexColor[3], 16);
	} else {
		r = parseInt(hexColor.substring(1, 3), 16);
		g = parseInt(hexColor.substring(3, 5), 16);
		b = parseInt(hexColor.substring(5, 7), 16);
	}

	const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
	const threshold = 128;

	return luminance < threshold;
}
