import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
	selector: '[appFixDiv]',
})
export class FixDivDirective {
	private scrollLimit: number = 520;

	constructor(private el: ElementRef, private renderer: Renderer2) {}

	@HostListener('window:scroll', [])
	onWindowScroll() {
		const scrollY = window.scrollY || window.pageYOffset;

		if (scrollY >= this.scrollLimit) {
			this.renderer.addClass(this.el.nativeElement, 'fix');
		} else {
			this.renderer.removeClass(this.el.nativeElement, 'fix');
		}
	}
}
