<div class="h-100 dashed-border space-between-align-center border-color-disabled py-12 px-48 position-relative">
	<div class="d-flex-align-center gap-20">
		<ng-container *ngIf="loading">
			<mat-progress-spinner
				[color]="'green'"
				[mode]="'indeterminate'"
				[diameter]="40"></mat-progress-spinner>
		</ng-container>

		<ng-container *ngIf="!loading">
			<ng-container *ngIf="uploadImage">
				<img
					[src]="uploadImage"
					class="preview-logo"
					*ngIf="type === 'logo'" />
				<img
					[src]="uploadImage"
					class="preview-background"
					*ngIf="type === 'background'" />
			</ng-container>

			<ng-container *ngIf="!uploadImage">
				<span class="i-matcha-upload font-size-32"></span>
				<h3 class="title font-weight-700 font-size-20 pt-16">Enviar imagem</h3>
			</ng-container>
		</ng-container>
	</div>
	<div class="d-flex-column">
		<span class="par-m font-size-16 text-center font-weight-500">
			Dimensões: {{ dimensions }} pixels
			<br />
			Formato: PNG ou SVG
		</span>
	</div>

	<input
		#imageFile
		class="w-100-p h-100-p cursor-pointer flow-hidden align-top align-left position-absolute"
		style="opacity: 0; min-width: 200px; min-height: 100px"
		type="file"
		id="adv-image-input"
		accept="image/x-png,image/gif,image/jpeg"
		(change)="imageChangeEvent($event)" />
</div>
