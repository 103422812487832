import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { StepsService } from 'src/app/features/services/steps/steps.service';
import { UploadImageService } from './upload-image.service';

@Component({
	selector: 'upload-image',
	templateUrl: './upload-image.component.html',
	styleUrls: ['./upload-image.component.scss'],
})
export class UploadImageComponent implements OnInit {
	@Input()
	dimensions: string = '650x650';
	@Input()
	formats: string = 'png/svg';
	@Input()
	public select_image_control: AbstractControl<any, any> | null = new FormControl();
	@Input()
	public uploadImage: string = '';

	public loading: boolean = false;

	@Input() type: string = 'logo';

	constructor(private imageConversionService: UploadImageService, private stepsServices: StepsService) {}

	ngOnInit(): void {
		if (this.uploadImage.indexOf('default_logo') > -1) {
			this.uploadImage = '';
		}
	}

	async imageChangeEvent($event: Event) {
		this.loading = true;

		const fileInput = $event.target as HTMLInputElement;
		const files = fileInput.files;
		if (files && files.length > 0) {
			const file = files[0];
			try {
				if (file) {
					const dimensions = this.dimensions.trim().split('x');
					const data = await this.imageConversionService.convertImageToBase64(
						file,
						this.formats,
						dimensions[0] as unknown as number,
						dimensions[1] as unknown as number
					);
					this.stepsServices.convertImage(data).subscribe((res: any) => {
						this.select_image_control ? this.select_image_control.setValue(res.image_url) : null;
						this.uploadImage = res.image_url;

						this.loading = false;
					});
				}
			} catch (error) {
				this.loading = false;
				console.error(error);
			}
		}
	}
}
