import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { StepsFormBuilderService } from '../../services/form-builder/steps-form-builder.service';

@Component({
	selector: 'step-six-store',
	templateUrl: './step-six-store.component.html',
	styleUrls: ['./step-six-store.component.scss'],
})
export class StepSixStoreComponent implements OnInit {
	public form: FormGroup = new FormGroup({});
	public nameControl: AbstractControl | null = null;
	public imageControl: AbstractControl | null = null;
	public cellphoneNameControl: AbstractControl | null = null;
	public iconCellphoneControl: AbstractControl | null = null;
	public colorsIconControl: AbstractControl | null = null;
	public colorsTextControl: AbstractControl | null = null;
	public colorsBackgroundControl: AbstractControl | null = null;
	public colorsControl: AbstractControl | null = null;
	constructor(private stepsFormBuilderService: StepsFormBuilderService) {}

	ngOnInit(): void {
		this.form = this.stepsFormBuilderService.getStoreForm();
		this.nameControl = this.form.get('name');
		this.cellphoneNameControl = this.form.get('cellphone_name');
		this.iconCellphoneControl = this.form.get('icon');
		this.colorsIconControl = this.form.get('colors.icon');
		this.colorsTextControl = this.form.get('colors.text');
		this.colorsBackgroundControl = this.form.get('colors.background');
		this.colorsControl = this.form.get('colors');
	}

	onChangeForm(event: Event, fieldControl: AbstractControl | null): void {
		const value = (event.target as HTMLInputElement).value;
		fieldControl && fieldControl.setValue(value);
	}

	onColorChange(color: { newColor: string }, ref: string) {
		const formGroup = this.colorsControl;

		if (formGroup && ref && formGroup.get(ref)) {
			formGroup.patchValue({
				[ref]: color.newColor,
			});
		}
	}
}
