<div class="d-flex-column border-radius-8 gap-16 background-placeholder-alpha p-24">
	<div class="d-flex-align-center gap-8">
		<span class="i-matcha-info-out"></span>
		<span
			data-testid="title"
			class="h5 font-weight-700">
			{{ title }}
		</span>
	</div>
	<div>
		<ul *ngFor="let par of formattedDescription()">
			<li>
				<p
					data-testid="description-card-info"
					class="par-m color-label font-weight-500 pl-8">
					{{ par }}
				</p>
			</li>
		</ul>
	</div>
</div>
