import { Component } from '@angular/core';

@Component({
	selector: 'error-page',
	templateUrl: './error-page.component.html',
	styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent {
	video = {
		preview: 'https://www.youtube.com/watch?v=_du5gqx8RIE',
		thumbnail: '../../../assets/thumbnail.png',
	};
}
