import { Component } from '@angular/core';

@Component({
	selector: 'upload-icon',
	templateUrl: './upload-icon.component.html',
	styleUrls: ['./upload-icon.component.scss'],
})
export class UploadIconComponent {
	//TODO - Add upload icon component
	imageChangeEvent(event: any) {}
}
