<div class="wrapper">
	<div class="header">
		<div
			class="background-logo"
			[style.background]="logoBackground">
			<img
				class="image-logo"
				[src]="logoIcon" />
		</div>
		<div class="name-container">
			<span [ngClass]="luminanceBgColor ? 'color-fg-inverse' : 'color-fg'">
				{{ churchName || 'Nome da Igreja na loja' }}
			</span>
			<small [ngClass]="luminanceBgColor ? 'color-fg-inverse' : 'color-fg'">inChurch</small>
			<div>
				<button
					class="matcha-button-pill matcha-button-xs button-enter"
					mat-flat-button
					[style.background]="'#3478F6'">
					OBTER
				</button>
			</div>
		</div>
	</div>
	<div class="divider"></div>
	<img
		style="width: 270px"
		[src]="imageIosUse"
		alt="" />

	<div
		class="image-background"
		[style.background]="backgroundCard">
		<span [style.color]="textColor">A igreja 24 horas com você. 🙏</span>
		<img
			src="../../../../assets/preview-store/cellphone.png"
			alt="" />
	</div>
</div>
