import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/app/environments/environment';
@Injectable({
	providedIn: 'root',
})
export class StepsService {
	private url = `${environment.apiUrl}`;
	private opsUrl = `${environment.opsUrl}`;

	constructor(private http: HttpClient) {}

	getInitalState(appConfigId: string) {
		return this.http.get(`${this.url}/app_configuration/${appConfigId}`);
	}

	save(appConfigId: string, value: any, inDev: boolean = false) {
		return this.http.patch(`${this.url}/app_configuration/${appConfigId}`, { ...value, in_dev: inDev });
	}
	createCard(value: any) {
		return this.http.post(`${this.opsUrl}/create-card`, { ...value });
	}

	convertImage(value: any) {
		return this.http.post(`${this.url}/upload-image`, value);
	}
}
