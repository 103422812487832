import { IAppCreationStep } from '../app-creation';

export const STEPS_VALUES: IAppCreationStep[] = [
	{ title: 'Tela inicial', path: 'tela-inicial', isLite: true, stage: 1 },
	{ title: 'Tela de abertura', path: 'tela-de-abertura', isLite: true, stage: 2 },
	{ title: 'Tela de login', path: 'tela-de-login', isLite: true, stage: 3 },
	{ title: 'Menu', path: 'menu', isLite: false, stage: 4 },
	{ title: 'Perfil', path: 'perfil', isLite: false, stage: 5 },
	{ title: 'Loja', path: 'loja', isLite: true, stage: 6 },
	{ title: 'Enviar', path: 'enviar', isLite: true, stage: 7 },
];
