<app-page-layout>
	<app-card-step-title
		title="Conteúdo obrigatório:"
		description="Você irá configurar a página do seu aplicativo nas lojas (Google Play Store e Apple App Store) além de criar o ícone do seu aplicativo."></app-card-step-title>
	<div class="d-flex gap-24">
		<div class="w-50-p d-flex-column gap-16">
			<app-preview>
				<preview-store></preview-store>
			</app-preview>
		</div>
		<div class="w-100-p d-flex-column gap-16">
			<app-section-page title="Página da loja">
				<app-card-section title="Nome do aplicativo na loja">
					<span class="par-p pl-24 color-label font-weight-500">
						Com base no nome da igreja, defina o nome do aplicativo que aparecerá na loja.
					</span>

					<app-input
						label="Nome do aplicativo"
						placeholder="Nome"
						name="Nome"
						[value]="nameControl?.value"
						[valueLength]="nameControl?.value.length"
						maxLength="30"
						(input)="onChangeForm($event, nameControl)"></app-input>
					<app-card-info
						title="Atenção"
						description="Na Apple Store, o aplicativo só será liberado desde que o nome na loja esteja disponível, mas fique tranquilo, pois estaremos prontos para ajudar caso você precise de suporte."></app-card-info>
				</app-card-section>
				<app-card-section title="Nome do aplicativo no celular">
					<span class="par-p pl-24 color-label font-weight-500">
						Com base no nome da igreja, defina o nome do aplicativo que aparecerá no celular.
					</span>
					<app-input
						label="Nome do aplicativo no celular"
						placeholder="Nome"
						name="Nome"
						[value]="cellphoneNameControl?.value"
						[valueLength]="cellphoneNameControl?.value.length"
						maxLength="16"
						(input)="onChangeForm($event, cellphoneNameControl)"></app-input>
					<app-card-info
						title="Importante"
						description="Pra evitar que o nome do aplicativo seja cortado ou apareça com reticências, é melhor usar até 13 caracteres, mesmo assim em alguns celulares pode acontecer abreviações."></app-card-info>
				</app-card-section>
				<app-card-section
					title="Ícone da loja e do celular"
					urlTemplate="http://">
					<span class="par-p pl-24 color-label font-weight-500">
						O ícone do aplicativo é um acesso visual no seu celular, facilitando a identificação rápida
						durante as buscas nas lojas (Play Store e App Store). Esse mesmo ícone aparecerá tanto dentro do
						seu celular quanto na página da loja.
					</span>
					<upload-image
						[select_image_control]="iconCellphoneControl"
						dimensions="512x512"></upload-image>

					<app-card-info
						title="Aqui vão algumas dicas sobre o ícone:"
						description="Utilize apenas o símbolo, a fim de garantir uma exibição legível, harmoniosa e visualmente limpa do ícone e insira o logotipo com fundo transparente para um visual bonito"></app-card-info>
					<span class="underline color-label font-weight-700 font-size-16">
						Precisa de ajuda?
						<a
							href="https://wa.link/ztl37o"
							target="_blank"
							style="text-decoration-line: underline"
							class="color-base">
							Clique aqui!
						</a>
					</span>
				</app-card-section>
				<app-card-section title="Fundo do ícone ">
					<div class="height-fit">
						<span class="par-p color-label font-weight-500">
							Estamos configurando automaticamente a cor primária como fundo dessa tela. Se houver algum
							problema de contraste, selecione uma nova cor abaixo.
						</span>
						<pick-color
							[color]="colorsIconControl?.value"
							class="pl-24 pt-24"
							openPosition="right"
							(changeColor)="onColorChange($event, 'icon')"></pick-color>
					</div>
				</app-card-section>
			</app-section-page>
			<app-section-page
				title="Imagens da loja"
				*ngIf="false">
				<app-card-section title="Imagens demonstrativas">
					<span class="par-p pl-24 color-label font-weight-500">
						Nos campos abaixo você pode personalizar as frases que vão em cima da imagem do celular, como
						mostra o protótipo ao lado.
					</span>
					<div class="d-flex-column gap-16">
						<app-input
							label="Frase para a tela inicial"
							placeholder="Frases"
							name="Frases"
							valueLength="0"
							maxLength="40"></app-input>
						<app-input
							label="Frase para a tela de palavras"
							placeholder="Frases"
							name="Frases"
							valueLength="0"
							maxLength="40"></app-input>
						<app-input
							label="Frase para tela da bíblia"
							placeholder="Frases"
							name="Frases"
							valueLength="0"
							maxLength="40"></app-input>
						<app-input
							label="Frase para tela de login"
							placeholder="Frases"
							name="Frases"
							valueLength="0"
							maxLength="40"></app-input>
						<app-input
							label="Frase para a última tela"
							placeholder="Frases"
							name="Frases"
							valueLength="0"
							maxLength="40"></app-input>
						<span class="underline color-label font-weight-700 font-size-16">
							Precisa de ajuda?
							<a
								href="https://wa.link/ztl37o"
								target="_blank"
								style="text-decoration-line: underline"
								class="color-base">
								Clique aqui!
							</a>
						</span>
					</div>
				</app-card-section>
				<app-card-section title="Cores da vitrine  (opcional) ">
					<div class="height-fit">
						<span class="par-p color-label font-weight-500">
							Nos campos abaixo você pode escolher a cor de fundo das imagens da loja como também a cor
							das frases acima.
						</span>
						<div>
							<div class="background-surface p-12 border-radius-8 h-100-p">
								<span>Cor do fundo</span>
								<pick-color
									[color]="colorsBackgroundControl?.value"
									class="pl-24 pt-24"
									openPosition="right"
									(changeColor)="onColorChange($event, 'background')"></pick-color>
							</div>
							<div class="background-surface p-12 border-radius-8 h-100-p">
								<span>Cor do texto</span>
								<pick-color
									[color]="colorsTextControl?.value"
									class="pl-24 pt-24"
									openPosition="left"
									(changeColor)="onColorChange($event, 'text')"></pick-color>
							</div>
						</div>
						<app-card-info
							title="Um minutinho da sua atenção"
							description="Nossa vitrine será entregue assim por padrão, mas relaxa, você pode trocar as imagens quando quiser. Só toma cuidado, pois podem rolar alguns problemas com a Apple ou a Google. Se precisar, contrate um profissional para garantir a melhor experiência."></app-card-info>
					</div>
				</app-card-section>
			</app-section-page>
		</div>
	</div>
</app-page-layout>
