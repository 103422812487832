<div class="h-100 dashed-border space-between-align-center border-color-disabled py-12 px-48">
	<div class="d-flex-align-center gap-20">
		<span class="i-matcha-upload font-size-32"></span>
		<h3 class="title font-weight-700 font-size-20 pt-16">Enviar video</h3>
	</div>
	<div class="d-flex-column">
		<span class="par-m font-size-16 text-center font-weight-500">
			Dimensões: 2160x3840 pixels
			<br />
			Formato: PNG ou SVG
		</span>
	</div>
	<input
		#imageFile
		class="z-index--9999 w-100-p h-100-p cursor-pointer flow-hidden align-top align-left position-absolute"
		style="opacity: 0"
		type="file"
		id="adv-image-input"
		accept="image/x-png,image/gif,image/jpeg"
		value=""
		(change)="videoChangeEvent($event, 'logo', '400px', '16 / 9')" />
</div>
