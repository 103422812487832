import { ICardColor } from '../@types/change-theme.types';

export const CHANGE_THEME_CARDS: ICardColor[] = [
	{
		name: 'Cor sobre o destaque',
		isDark: false,
		isAdvanced: false,
		ref: 'on_accent',
		color: '',
	},
	{
		name: 'Cor sobre o destaque',
		isDark: true,
		isAdvanced: false,
		ref: 'on_accent',
		color: '',
	},
	{
		name: 'Cor destaque',
		isDark: false,
		isAdvanced: false,
		ref: 'primary',
		color: '',
	},
	{
		name: 'Cor destaque',
		isDark: true,
		isAdvanced: false,
		ref: 'primary',
		color: '',
	},
	{
		name: 'Cor de fundo',
		isDark: false,
		isAdvanced: true,
		ref: 'background',
		color: '',
	},
	{
		name: 'Cor de fundo',
		isDark: true,
		isAdvanced: true,
		ref: 'background',
		color: '',
	},
	{
		name: 'Cor da superfície',
		isDark: false,
		isAdvanced: true,
		ref: 'surface',
		color: '',
	},
	{
		name: 'Cor da superfície',
		isDark: true,
		isAdvanced: true,
		ref: 'surface',
		color: '',
	},
];
