<div class="width-x-height">
	<header class="flex-center-space-between">
		<img
			class="icon"
			[src]="logoIcon" />
		<span class="i-matcha-profile"></span>
	</header>
	<main class="main">
		<img
			class="img-background"
			src="../../../../assets/preview-theme/paisagem.png" />

		<preview-cards></preview-cards>

		<button
			class="matcha-button-md button"
			mat-raised-button
			[ngClass]="luminanceColor ? 'color-fg-inverse' : 'color-fg'"
			[style.background]="primaryColor || '#1E5BDA'">
			<span
				class="fs-14 i-matcha-live"
				[style.color]="onAccentColor"></span>
			<span [style.color]="onAccentColor">Estamos ao vivo. Clique e assista</span>
		</button>

		<div class="max-width">
			<h5
				class="fs-16 pl-12"
				[ngClass]="luminanceBgColor ? 'color-fg-inverse' : 'color-fg'">
				Destaques
			</h5>
			<img
				class="img-background"
				src="../../../../assets/preview-theme/destaque.png" />
		</div>
	</main>
</div>
