<app-header
	title="personalize seu aplicativo"
	description="Voltar ao painel"></app-header>
<app-page-layout>
	<div class="d-flex gap-24">
		<div class="w-50-p d-flex-column gap-16">
			<app-preview>
				<div>olá</div>
			</app-preview>
		</div>
		<div class="w-100-p d-flex-column gap-16">
			<app-card-section style="height: 595px">
				<div
					style="height: 595px"
					class="d-flex-column gap-40 padding-card">
					<h1 class="font-size-36 text-ajustsments">
						<span class="mr-40 i-matcha-rocket font-size-58"></span>
						Estamos quase lá...
					</h1>

					<span class="par-m font-weight-500 font-size-16 color-label order-0 description">
						<b class="color-text">
							No quadro ao lado, você pode conferir se tudo está correto com o seu
							<br />
							app.
						</b>
						<br />
						<br />
						Se estiver tudo bem, basta clicar no botão abaixo "Enviar para
						<br />
						desenvolvimento".
						<br />
						Se precisar fazer alguma alteração, você pode clicar em "Ajustar" e
						<br />
						modificar os pontos que não estão com a cara da sua igreja!
						<br />
						<br />
						<span class="par-lg font-size-24 font-weight-700 color-text">
							Lembre-se dos prazos: até 15 dias úteis para o
							<br />
							desenvolvimento, desde que as informações enviadas
							<br />
							estejam corretas, e mais 7 dias úteis para que o aplicativo
							<br />
							esteja disponível nas lojas.
						</span>
						<br />
						<br />
						<b class="par-md color-text">
							Importante: Após ser enviado para desenvolvimento, qualquer alteração
							<br />
							<b style="text-decoration-line: underline">
								no aplicativo estará sujeita a uma taxa de 250 reais.
							</b>
						</b>
					</span>
					<div class="d-flex gap-16">
						<button
							class="matcha-button-md color-label button-previous cursor-pointer"
							mat-raised-button>
							Ajustar
						</button>
						<button
							class="matcha-button-md button-advance"
							mat-stroked-button>
							Enviar para desenvolvimento
						</button>
					</div>
				</div>
			</app-card-section>
		</div>
	</div>
</app-page-layout>
