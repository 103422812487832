import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-card-step-title',
	templateUrl: './card-step-title.component.html',
	styleUrls: ['./card-step-title.component.scss'],
})
export class CardStepTitleComponent {
	@Input() title: string = '';
	@Input() description: string = '';
}
