<div id="content">
	<div class="phone">
		<div class="button-1 cellphone-buttons"></div>
		<div class="button-2 cellphone-buttons"></div>
		<div class="button-3 cellphone-buttons"></div>
		<div class="button-4 cellphone-buttons"></div>
		<div
			class="screen"
			[ngStyle]="{ 'background-color': color }">
			<div class="top">
				<div class="dock">
					<div class="speaker"></div>
					<div class="sensor"></div>
				</div>
			</div>
			<div
				class="background"
				[ngStyle]="{ 'background-color': color }">
				<ng-content></ng-content>
			</div>
		</div>
	</div>
</div>
