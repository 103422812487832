import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-preview',
	templateUrl: './preview.component.html',
	styleUrls: ['./preview.component.scss'],
})
export class PreviewComponent {
	@Input()
	public isActionsVisible: boolean = false;
	@Output()
	handlePreviousClick = new EventEmitter();
	@Output()
	handleNextClick = new EventEmitter();

	onClickPrevious($event: MouseEvent) {
		this.handlePreviousClick.emit($event);
	}
	onClickNext($event: MouseEvent) {
		this.handleNextClick.emit($event);
	}
}
