import { Component, OnInit, effect } from '@angular/core';
import { FormStateServiceService } from 'src/app/features/services/state/form-state-service.service';
import { isColorDark } from 'src/utils/isColorDark';

@Component({
	selector: 'preview-logo-and-theme',
	templateUrl: './preview-logo-and-theme.component.html',
	styleUrls: ['./preview-logo-and-theme.component.scss'],
})
export class PreviewLogoAndThemeComponent implements OnInit {
	public logoIcon: string = '';
	public primaryColor: string = '';
	public onAccentColor: string = '';
	public backgroundColor: string = '';
	public theme: 'light' | 'dark' = 'light';
	public luminanceColor: boolean = false;
	public luminanceBgColor: boolean = false;

	constructor(private stepsStateService: FormStateServiceService) {
		effect(() => {
			this.logoIcon = this.stepsStateService.getState().home_screen.home.logo;
			this.theme = this.stepsStateService.getState().home_screen.home.selectTheme as 'light' | 'dark';
			this.primaryColor = this.stepsStateService.getState().home_screen.colors[this.theme].primary;
			this.onAccentColor = this.stepsStateService.getState().home_screen.colors[this.theme].on_accent;
			this.luminanceColor = isColorDark(this.primaryColor);

			this.backgroundColor = this.stepsStateService.getState().home_screen.colors[this.theme].background;
			this.luminanceBgColor = isColorDark(this.backgroundColor);
		});
	}

	ngOnInit(): void {}
}
