import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { catchError, map, of } from 'rxjs';
import { StepsFormBuilderService } from './features/services/form-builder/steps-form-builder.service';
import { FormStateServiceService } from './features/services/state/form-state-service.service';
import { StepsService } from './features/services/steps/steps.service';

export const authGuard: CanActivateFn = (route, state) => {
	const stepService = inject(StepsService);
	const stepFormStateService = inject(FormStateServiceService);
	const formStateService = inject(StepsFormBuilderService);

	const router = inject(Router);
	const id = route.paramMap.get('appConfigId');

	if (!id) return false;

	return stepService.getInitalState(id).pipe(
		map((appConfig) => handleStateUpdate(appConfig, formStateService, stepFormStateService, router, id)),
		catchError((err) => {
			console.error(err);
			router.navigate([`error`]);
			return of(false);
		})
	);
};

function handleStateUpdate(appConfig: any, formStateService: any, stepFormStateService: any, router: any, id: string) {
	if (!localStorage.getItem('subgroup_id')) {
		localStorage.setItem('subgroup_id', appConfig['subgroup_id']);
	}

	if (appConfig['in_dev']) {
		router.navigate([`/desenvolvimento`]);
		return false;
	}

	if (appConfig['stage'] === 0) {
		formStateService.createStepsLiteForm();
		return true;
	}

	const stateSteps = createStateSteps(appConfig, id);
	stepFormStateService.setState(stateSteps);
	updateForms(formStateService, stateSteps);

	return true;
}

function createStateSteps(appConfig: any, id: string) {
	return {
		home_screen: {
			home: { ...appConfig['home'], selectTheme: appConfig['mode'] || 'light' },
			colors: appConfig['colors'],
		},
		splash: appConfig['splash'],
		login: appConfig['login'],
		store: appConfig['store'],
		stage: appConfig['stage'],
		subgroup_id: appConfig['subgroup_id'],
		id: id,
	};
}

function updateForms(formStateService: any, stateSteps: any) {
	if (!formStateService.getHomeScreenForm()) return formStateService.createStepsLiteForm();
}
