import { Component, effect } from '@angular/core';
import { FormStateServiceService } from 'src/app/features/services/state/form-state-service.service';

@Component({
	selector: 'preview-splash',
	templateUrl: './preview-splash.component.html',
	styleUrls: ['./preview-splash.component.scss'],
})
export class PreviewSplashComponent {
	public logoIcon: string = '../../../../assets/preview-splash/logo-default.png';
	public backgroundIcon: string = '../../../../assets/preview-splash/background.png';
	public inchurchLogoColor: string = '#000';

	constructor(private stepsStateService: FormStateServiceService) {
		effect(() => {
			this.logoIcon = this.stepsStateService.getState().splash.logo;
			this.backgroundIcon = this.stepsStateService.getState().splash.background;
			this.inchurchLogoColor = this.stepsStateService.getState().splash.logo_color;
		});
	}
	ngOnInit(): void {}
}
