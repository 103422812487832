<div
	class="center-center"
	[ngClass]="{ 'no-image': !backgroundIcon }">
	<img
		class="background"
		[src]="backgroundIcon"
		alt="" />
	<img
		class="logo"
		[src]="logoIcon"
		alt="" />
	<div class="powered">
		<span [style.color]="inchurchLogoColor">powered by inchurch</span>
	</div>
</div>
