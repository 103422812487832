<app-page-layout>
	<div class="d-flex gap-24 wrapper">
		<div class="w-50-p d-flex-column gap-16">
			<app-preview
				[isActionsVisible]="true"
				(handleNextClick)="next()"
				(handlePreviousClick)="prev()">
				<ng-container *ngFor="let splash of splashs; let i = index">
					<ng-container [ngSwitch]="splashs[activeIndex].type">
						<preview-logo-and-theme *ngSwitchCase="'home'"></preview-logo-and-theme>
						<preview-splash *ngSwitchCase="'splash'"></preview-splash>
						<preview-login *ngSwitchCase="'login'"></preview-login>
						<preview-store *ngSwitchCase="'store'"></preview-store>
					</ng-container>
				</ng-container>
			</app-preview>
		</div>
		<div class="w-100-p d-flex-column gap-16">
			<app-card-section style="height: 595px">
				<div
					style="height: 595px"
					class="d-flex-column gap-40 padding-card">
					<h1 class="font-size-32 max-width-480 text-ajustsments">
						Parabéns por personalizar
						<br />
						seu aplicativo
						<b
							class="font-size-32 max-width-480 text-ajustsments"
							style="color: #1e5bda">
							lite!
						</b>
					</h1>

					<span class="par-m font-weight-500 font-size-16 color-label order-0 description">
						Seu aplicativo está em processo de desenvolvimento. Enviaremos suas personalizações para nossa
						equipe, que criará o aplicativo e o enviará para as lojas do Google e da Apple.
						<br />
						<br />
						<b
							class="color-text"
							style="text-decoration-line: underline">
							Lembre-se dos prazos: até 15 dias úteis para o desenvolvimento, desde que as informações
							enviadas estejam corretas, e mais 7 dias úteis para que o aplicativo esteja disponível nas
							lojas.
						</b>
						<br />
						<br />
						<span class="par-lg font-size-24 font-weight-700 color-text">
							Agora vem a parte importante!
						</span>
						<br />
						<br />
						Para que seu aplicativo seja aceito nas lojas,
						<b class="color-text">
							é importante adicionar
							<br />
							conteúdo!
						</b>
						A boa notícia é que você já pode ir adiantando isso enquanto o time de desenvolvimento trabalha.
						<br />
						<br />
						<b class="color-text">
							No quadro ao lado, você pode conferir se tudo está correto com o seu app.
						</b>
						<br />
						Se estiver tudo bem, basta clicar no botão abaixo "Enviar e avançar". Você será direcionado para
						o painel, onde poderá começar a criar seu primeiro conteúdo. Se precisar fazer alguma alteração,
						você pode clicar em "Voltar" e ajustar os pontos que não estão com a cara da sua igreja!
						<br />
						<br />
						Acesse a XMission pelo
						<a
							href="https://admin.inchurch.com.br/"
							target="_blank">
							painel da sua igreja
						</a>
						para ter acesso a conteúdos exclusivos como treinamentos, missões e cursos que vão te ajudar a
						extrair todo potencial do seu aplicativo.
					</span>
					<div class="d-flex gap-16 pb-24">
						<button
							(click)="onPrevious()"
							class="matcha-button-md color-label button-previous cursor-pointer"
							mat-raised-button>
							Voltar
						</button>
						<button
							(click)="onSubmit(true)"
							class="matcha-button-md button-advance button-submit"
							mat-stroked-button>
							Enviar e avançar
						</button>
					</div>
				</div>
			</app-card-section>
		</div>
	</div>
</app-page-layout>
