import { Routes } from '@angular/router';
import { authGuard } from '../auth.guard';
import { AcceptTermsCustomizeAppComponent } from './accept-terms-customize-app/accept-terms-customize-app.component';
import { AppCreationStepsRoutes } from './app-creation-steps/app-creation-steps.routes';
import { DoneSendAppComponent } from './app-creation-steps/done-send-app/done-send-app.component';
import { AppInDevComponent } from './app-in-dev/app-in-dev.component';
import { CustomizeAppComponent } from './customize-app/customize-app.component';
import { ErrorPageComponent } from './error-page/error-page.component';

export const FeaturesRoutes: Routes = [
	{
		path: '',
		component: CustomizeAppComponent,
		canActivate: [authGuard],
	},
	{
		path: 'desenvolvimento',
		component: AppInDevComponent,
	},
	{
		path: 'aceitar_termos/:appConfigId',
		component: AcceptTermsCustomizeAppComponent,
		canActivate: [authGuard],
	},
	{ ...AppCreationStepsRoutes },
	{
		path: 'done/:appConfigId',
		component: DoneSendAppComponent,
	},
	{
		path: '**',
		component: ErrorPageComponent,
	},
];
