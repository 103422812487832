<div class="background-surface border-radius-8">
	<div class="p-24">
		<div class="mt-12">
			<h4 class="title">
				<span class="title-bullet background-surface-inverse h-24 w-8"></span>
				<div class="d-flex-center-center">
					{{ title }}
					<span
						id="description"
						class="par-p pl-24 color-label font-weight-500">
						{{ description }}
					</span>
				</div>
			</h4>
		</div>
		<ng-content></ng-content>
	</div>
</div>
