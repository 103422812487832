import { Component, OnInit, effect } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { StepsFormBuilderService } from '../../services/form-builder/steps-form-builder.service';
import { FormStateServiceService } from '../../services/state/form-state-service.service';

@Component({
	selector: 'step-one-initial-page',
	templateUrl: './step-one-initial-page.component.html',
	styleUrls: ['./step-one-initial-page.component.scss'],
})
export class StepOneInitialPageComponent implements OnInit {
	// TODO: get this value from the plan selected
	public isLite: boolean = true;
	public form: FormGroup = new FormGroup({});
	public logoControl: AbstractControl | null = null;
	public colorsControl: AbstractControl | null = null;
	public themeControl: AbstractControl | null = null;
	public uploadImage: string = '';
	public closedTutorial = false;
	constructor(private stepsFormService: StepsFormBuilderService, private stepsStateService: FormStateServiceService) {
		effect(() => {
			this.uploadImage = this.stepsStateService.getState().home_screen.home.logo;
		});
	}

	ngOnInit(): void {
		this.form = this.stepsFormService.getHomeScreenForm();
		this.logoControl = this.form.get('home.logo');
		this.themeControl = this.form.get('home.selectTheme');
		this.colorsControl = this.form.get('colors');
	}

	onCloseTutorial() {
		//TODO -save in local storage
		this.closedTutorial = true;
	}
	//TODO create redirected salve and quit method
}
