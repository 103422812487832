<app-header
	title="personalize seu aplicativo"
	description="Voltar ao painel"></app-header>
<app-page-layout>
	<div class="error-container">
		<h1>Oops!</h1>
		<p>Algo deu errado.</p>
		<a routerLink="/">Voltar para a página inicial</a>
	</div>
</app-page-layout>
