import { Route } from '@angular/router';
import { authGuard } from 'src/app/auth.guard';
import { AppCreationStepsComponent } from './app-creation-steps.component';
import { DoneStepInAppLiteComponent } from './done-step-in-app-lite/done-step-in-app-lite.component';
import { StepFivePerfilComponent } from './step-five-perfil/step-five-perfil.component';
import { StepFourMenuComponent } from './step-four-menu/step-four-menu.component';
import { StepOneInitialPageComponent } from './step-one-initial-page/step-one-initial-page.component';
import { StepSixStoreComponent } from './step-six-store/step-six-store.component';
import { StepThreeLoginScreenComponent } from './step-three-login-screen/step-three-login-screen.component';
import { StepTwoOpeningScreenComponent } from './step-two-opening-screen/step-two-opening-screen.component';

export const AppCreationStepsRoutes: Route = {
	path: 'criar-app/:appConfigId',
	component: AppCreationStepsComponent,
	children: [
		{
			path: '',
			redirectTo: 'tela-inicial',
			pathMatch: 'full',
		},
		{
			path: 'tela-inicial',
			component: StepOneInitialPageComponent,
		},
		{
			path: 'tela-de-abertura',
			component: StepTwoOpeningScreenComponent,
		},
		{
			path: 'tela-de-login',
			component: StepThreeLoginScreenComponent,
		},
		{
			path: 'menu',
			component: StepFourMenuComponent,
		},
		{
			path: 'perfil',
			component: StepFivePerfilComponent,
		},
		{
			path: 'loja',
			component: StepSixStoreComponent,
		},
		{
			path: 'enviar',
			component: DoneStepInAppLiteComponent,
		},
	],
	canActivate: [authGuard],
};
