<div class="d-flex gap-8 ml-36">
	<div>
		<img
			src="../../../../../assets/preview-theme/cards-1.png"
			alt="Atitudes que geram mudanças" />
		<span
			class="text-cards"
			[ngClass]="luminanceBgColor ? 'color-fg-inverse' : 'color-fg'">
			Atitudes que
			<br />
			geram mudanças
		</span>
	</div>
	<div>
		<img
			src="../../../../../assets/preview-theme/cards-2.png"
			alt="Vida espiritual" />
		<span
			[ngClass]="luminanceBgColor ? 'color-fg-inverse' : 'color-fg'"
			class="text-cards">
			Vida espiritual
		</span>
	</div>
	<div>
		<img
			src="../../../../../assets/preview-theme/cards3.png"
			alt="Entendendo os dons" />
		<span
			[ngClass]="luminanceBgColor ? 'color-fg-inverse' : 'color-fg'"
			class="text-cards">
			Entendendo os dons
		</span>
	</div>
</div>
