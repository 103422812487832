import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'pick-color',
	templateUrl: './select-color.component.html',
	styleUrls: ['./select-color.component.scss'],
})
export class SelectColorComponent {
	@Input()
	color = '';
	@Input()
	openPosition: 'left' | 'right' = 'left';
	@Output()
	changeColor = new EventEmitter();

	@Input()
	disable: boolean;
	constructor() {}

	setColor(color: string) {
		this.changeColor.emit({ newColor: color });
	}
}
