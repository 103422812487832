import { Component, effect } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { INITIAL_VALUES } from '../../services/state/constants';
import { FormStateServiceService } from '../../services/state/form-state-service.service';
import { AppState } from '../../services/state/state';
import { StepsService } from '../../services/steps/steps.service';

@Component({
	selector: 'done-step-in-app-lite',
	templateUrl: './done-step-in-app-lite.component.html',
	styleUrls: ['./done-step-in-app-lite.component.scss'],
})
export class DoneStepInAppLiteComponent {
	public state: AppState = INITIAL_VALUES;
	public activeIndex = 0;
	public splashs = [{ type: 'home' }, { type: 'splash' }, { type: 'login' }, { type: 'store' }];
	constructor(
		private router: Router,
		private stepsService: StepsService,
		private stepFormStateService: FormStateServiceService,
		private stepper: MatStepper
	) {
		effect(() => {
			this.state = this.stepFormStateService.getState();
		});
	}
	onSubmit(isFinished: boolean) {
		const transformedObject = formatObject(this.state);

		const url = this.router.url;
		const appConfigId = url.split('/')[2];

		this.stepsService.save(appConfigId, transformedObject, true).subscribe(() => {
			if (isFinished) {
				this.stepsService
					.createCard({
						app_config_id: appConfigId,
						subgroup_id: localStorage.getItem('subgroup_id') || '',
					})
					.subscribe(() => {});
			}
			this.router.navigateByUrl(`/done/${appConfigId}`);
		});
	}
	onPrevious() {
		this.stepper.previous();
	}
	next() {
		if (this.activeIndex < this.splashs.length - 1) {
			this.activeIndex = this.activeIndex + 1;
		}
	}

	prev() {
		if (this.activeIndex > 0) {
			this.activeIndex = this.activeIndex - 1;
		}
	}
}

function formatObject(object: AppState) {
	const transformedObject = {
		name: object.store.name,
		plan: '',
		icons_type: '',
		notification_icon: object.login.notification_icon,
		colors: {
			light: {
				background: object.home_screen.colors.light.background,
				primary: object.home_screen.colors.light.primary,
				surface: object.home_screen.colors.light.surface,
				accent: object.home_screen.colors.light.accent,
			},
			dark: {
				background: object.home_screen.colors.dark.background,
				primary: object.home_screen.colors.dark.primary,
				surface: object.home_screen.colors.dark.surface,
				accent: object.home_screen.colors.dark.accent,
			},
		},
		splash: {
			logo: object.splash.logo,
			background: object.splash.background,
			logo_color: object.splash.logo_color,
		},
		login: {
			logo: object.login.logo,
		},
		home: {
			logo: object.home_screen.home.logo,
			news: '',
			preaching: '',
			labels: {
				higlights: '',
				radios: '',
				news: '',
			},
		},
		menus: {
			profile: {
				type: '',
				items: [
					{
						title: '',
						action: '',
						image: '',
					},
				],
			},
			more: {
				type: '',
				items: [
					{
						title: '',
						action: '',
						image: '',
						args: [
							{
								type: '',
								value: '',
							},
						],
					},
				],
			},
		},
		store: {
			name: object.store.name,
			cellphone_name: object.store.cellphone_name,
			colors: object.store.colors,
			icon: object.store.icon,
			images: object.store.images,
		},
	};

	return transformedObject;
}
