<div class="d-flex-column gap-12 border-radius-8 border-3 background-yellow-200 border-color-yellow-900 p-16">
	<div class="d-flex-space-between">
		<span
			data-testid="title"
			class="h5 font-weight-700 opacity-10">
			{{ title }}
		</span>
		<button
			(click)="onClickCloseTutorial()"
			class="border-none background-transparent cursor-pointer"
			*ngIf="isCloseTutorial">
			<small class="color-yellow-900 font-weight-500 font-size-12">Fechar tutorial</small>
		</button>
	</div>
	<span
		data-testid="description"
		class="par-p color-label font-weight-500 opacity-10">
		{{ description }}
	</span>
</div>
