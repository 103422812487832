<div class="background-surface border-radius-8">
	<div class="d-flex-column gap-24 p-24 overflow-y-auto">
		<div
			*ngIf="title"
			class="space-between-align-center">
			<h4 class="title">
				<span class="title-bullet background-surface-alpha-inverse h-24 w-8"></span>
				{{ title }}
			</h4>
			<a
				*ngIf="!!urlTemplate"
				class="d-flex-center-center gap-8">
				<span class="h8 color-underline font-weight-700">Baixar Modelo</span>
				<span class="i-matcha-info-out color-secondary"></span>
			</a>
		</div>
		<ng-content></ng-content>
	</div>
</div>
