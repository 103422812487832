<app-header
	title="personalize seu aplicativo"
	description="Voltar ao painel"></app-header>
<div class="w-100-p content-actions">
	<mat-stepper
		#stepper
		class="stepper"
		[linear]="false"
		[selectedIndex]="selectedStepIndex"
		(selectionChange)="selectionChanged($event)"
		labelPosition="bottom">
		<mat-step *ngFor="let step of stepsFormatted; let i = index; trackBy: identify">
			<ng-template matStepLabel>{{ step.title }}</ng-template>
			<ng-template matStepContent>
				<ng-container *ngIf="i === selectedStepIndex">
					<form
						[formGroup]="form"
						storageStepsForm="steps-app-creation">
						<router-outlet></router-outlet>
						<div
							*ngIf="step.path !== 'enviar'"
							class="w-100-p content-actions">
							<div class="w-80-p">
								<app-actions-steps
									(handleSaveAndQuit)="saveAndQuit()"
									(handlePreviousClick)="onPreviousStep(stepper)"
									(handleSaveAndNext)="onSaveAndNext(stepper)"
									[isPreviousDisabled]="step.path === 'tela-inicial'"></app-actions-steps>
							</div>
						</div>
					</form>
				</ng-container>
			</ng-template>
		</mat-step>
		<ng-template matStepperIcon="done">
			<span class="i-matcha-star fs-16 text-center"></span>
		</ng-template>
		<ng-template matStepperIcon="edit">
			<span class="i-matcha-rocket fs-16 text-center"></span>
		</ng-template>
	</mat-stepper>
</div>
