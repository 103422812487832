<app-page-layout>
	<app-card-step-title
		title="Tela Inicial"
		description="Insira o logotipo, escolha o tema e cores do seu aplicativo e aproveite para montar a tela inicial do jeito que preferir."></app-card-step-title>

	<div class="d-flex gap-24">
		<div class="w-50-p d-flex-column gap-16">
			<app-card-tutorial
				*ngIf="!closedTutorial"
				[isCloseTutorial]="false"
				title="Dá uma olhada na imagem abaixo!"
				description="Ela mostra todas as alterações que você está fazendo. Ela vai estar aqui durante todo o processo e vai te ajudar a validar as mudanças."></app-card-tutorial>

			<app-preview>
				<preview-logo-and-theme></preview-logo-and-theme>
			</app-preview>
		</div>
		<div class="w-100-p d-flex-column gap-16">
			<app-card-tutorial
				*ngIf="!closedTutorial"
				(handleClickCloseTutorial)="onCloseTutorial()"
				title="Agora preste atenção nessa área aqui."
				description="Esse é o lugar onde você vai colocar todas as informações e tomar suas decisões de personalização. E o melhor é que ele vai te acompanhar durante todo o processo! Você pode salvar e sair a qualquer momento, e quando voltar, pode continuar de onde parou. É super prático!"></app-card-tutorial>

			<app-section-page title="Personalizações básicas">
				<app-card-section title="Logotipo">
					<span class="par-p pl-24 color-label font-weight-500">
						Insira abaixo o logotipo da sua igreja. Esse logotipo será posicionado no canto superior
						esquerdo em dispositivos Android e no centro em dispositivos iOS.
					</span>
					<upload-image
						[select_image_control]="logoControl"
						[uploadImage]="uploadImage"></upload-image>
					<app-card-info
						title="Aqui vão algumas dicas sobre a tela inicial do aplicativo:"
						description="Use um logotipo quadrado ou retangular. Insira o logotipo 
						com fundo transparente para um visual bonito. Confira o protótipo ao lado para ter uma ideia."></app-card-info>
					<span class="underline color-label font-weight-700 font-size-16">
						Precisa de ajuda?
						<a
							href="https://wa.link/ztl37o"
							target="_blank"
							style="text-decoration-line: underline"
							class="color-base">
							Clique aqui!
						</a>
					</span>
				</app-card-section>
				<app-card-section title="Personalização padrão x personalização avançada:">
					<span class="par-p color-label font-weight-500">
						Na personalização padrão, você pode escolher entre o tema escuro ou claro e definir as cores de
						destaque e cor primária. Já na personalização avançada, você pode personalizar os dois temas e
						decidir as cores de destaque, primária, fundo e superfície.
					</span>
					<span class="par-p color-label font-weight-500">
						A escolha é sua! Personalize seu aplicativo de acordo com seu estilo preferido.
					</span>
					<div
						class="d-flex gap-16 w-100-p"
						*ngIf="!isLite">
						<button
							class="matcha-button-lg bg-transparent"
							style="width: 100%; height: 56px"
							mat-stroked-button
							color="primary"
							type="button"
							tabindex="-1">
							<div class="d-flex-center-center gap-16">
								<span class="i-matcha-dashboard"></span>
								<h5 class="title font-weight-500 font-size-16 pt-12">Padrão</h5>
							</div>
						</button>
						<button
							class="matcha-button-lg bg-transparent"
							style="width: 100%; height: 56px"
							mat-stroked-button
							disabled="true"
							color="primary"
							type="button"
							tabindex="-1">
							<div class="d-flex-center-center gap-16">
								<span class="i-matcha-edit"></span>
								<h5 class="title font-weight-500 font-size-16 pt-12">Avançado</h5>
							</div>
						</button>
					</div>
					<h5 class="title color-label font-weight-700">
						Escolha agora a cor de destaque e a cor primária para o tema claro e escuro:
					</h5>
					<change-theme
						[change_theme_colors]="colorsControl"
						[change_select_theme]="themeControl"></change-theme>
					<app-card-info
						title="Dica importante!"
						description="Lembre-se da importância do contraste entre o tema e a cor de destaque. Se o tema for claro, recomendamos que utilize uma cor de destaque mais escura,
						 garantindo um contraste adequado e boa legibilidade."></app-card-info>
					<span class="underline color-label font-weight-700 font-size-16">
						Precisa de ajuda?
						<a
							href="https://wa.link/ztl37o"
							target="_blank"
							style="text-decoration-line: underline"
							class="color-base">
							Clique aqui!
						</a>
					</span>
				</app-card-section>
			</app-section-page>
			<app-section-page
				*ngIf="!isLite"
				title="Personalização da Tela Inicial">
				<app-card-section title="Formato das seções">
					<h5 class="title color-label font-weight-700">
						Escolha um formato para a seção de
						<b class="color-base pl-8">Palavras:</b>
					</h5>
					<div class="d-flex gap-16">
						<button
							class="matcha-button-lg bg-transparent"
							style="width: 100%; height: 56px"
							mat-stroked-button
							color="primary"
							type="button"
							tabindex="-1">
							<div class="d-flex-center-center gap-16">
								<span class="i-matcha-dashboard"></span>
								<h5 class="title font-weight-500 font-size-16 pt-12">Com carrossel</h5>
							</div>
						</button>
						<button
							class="matcha-button-lg bg-transparent"
							style="width: 100%; height: 56px"
							mat-stroked-button
							color="primary"
							type="button"
							tabindex="-1">
							<div class="d-flex-center-center gap-16">
								<span class="i-matcha-dashboard"></span>
								<h5 class="title font-weight-500 font-size-16 pt-12">Sem carrossel</h5>
							</div>
						</button>
					</div>
					<h5 class="title color-label font-weight-700">
						Escolha um formato para a seção de
						<b class="color-base pl-8">Noticias:</b>
					</h5>
					<div class="d-flex gap-16">
						<button
							class="matcha-button-lg bg-transparent"
							style="width: 100%; height: 56px"
							mat-stroked-button
							color="primary"
							type="button"
							tabindex="-1">
							<div class="d-flex-center-center gap-16">
								<span class="i-matcha-dashboard"></span>
								<h5 class="title font-weight-500 font-size-16 pt-12">Em carrossel</h5>
							</div>
						</button>
						<button
							class="matcha-button-lg bg-transparent"
							style="width: 100%; height: 56px"
							mat-stroked-button
							color="primary"
							type="button"
							tabindex="-1">
							<div class="d-flex-center-center gap-16">
								<span class="i-matcha-dashboard"></span>
								<h5 class="title font-weight-500 font-size-16 pt-12">Em lista</h5>
							</div>
						</button>
					</div>
				</app-card-section>
				<app-card-section title="Nomenclaturas das seções da tela inicial">
					<div class="h-100-p">
						<div class="d-flex gap-12">
							<mat-slide-toggle hideIcon></mat-slide-toggle>
							<div class="d-flex-column w-100-p gap-16">
								<div>
									<h5 class="title color-label font-weight-700 font-size-16">
										Modificar a nomenclatura de “Destaques”
									</h5>
									<span class="par-s color-label font-weight-500 font-size-12">
										A seção de 'Destaques' mostra um slide interativo com os banners da igreja
										(inseridos pelo painel).
									</span>
								</div>
								<app-input
									label="Nomenclatura de Destaques"
									placeholder="Destaques"
									name="Destaques"
									valueLength="0"
									maxLength="24"></app-input>
							</div>
						</div>
						<div class="d-flex gap-12">
							<mat-slide-toggle hideIcon></mat-slide-toggle>
							<div class="d-flex-column w-100-p gap-16">
								<div>
									<h5 class="title color-label font-weight-700 font-size-16">
										Modificar a nomenclatura de ”Rádios”
									</h5>
									<span class="par-s color-label font-weight-500 font-size-12">
										A seção de 'Rádios' apresenta um player com a transmissão da rádio habilitada
										pela igreja no painel.
									</span>
								</div>
								<app-input
									label="Nomenclatura de Rádios"
									placeholder="Rádios"
									name="Rádios"
									valueLength="0"
									maxLength="24"></app-input>
							</div>
						</div>
						<div class="d-flex gap-12">
							<mat-slide-toggle hideIcon></mat-slide-toggle>
							<div class="d-flex-column w-100-p gap-16">
								<div>
									<h5 class="title color-label font-weight-700 font-size-16">
										Modificar a nomenclatura de “Noticias”
									</h5>
									<span class="par-s color-label font-weight-500 font-size-12">
										A seção de Notícias exibe as notícias publicadas pela igreja através do painel.
									</span>
								</div>
								<app-input
									label="Nomenclatura de Noticias"
									placeholder="Noticias"
									name="Noticias"
									valueLength="0"
									maxLength="24"></app-input>
							</div>
						</div>
					</div>

					<app-card-info
						title="Lembre-se!"
						description="Os nomes serão alterados exclusivamente dentro do aplicativo da igreja. No painel administrativo, 
            as nomenclaturas seguirão o padrão estabelecido pela inChurch."></app-card-info>
				</app-card-section>
			</app-section-page>
		</div>
	</div>
</app-page-layout>
