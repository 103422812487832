import { AppState } from '../state';

export const INITIAL_VALUES: AppState = {
	home_screen: {
		home: {
			logo: '../../../../assets/default_logo.webp',
			selectTheme: 'light',
		},
		stage: 0,
		colors: {
			light: {
				primary: '',
				background: '#FFFFFF',
				surface: '',
				accent: '',
				on_accent: '',
			},
			dark: {
				primary: '',
				background: '#1F1F1F',
				surface: '',
				accent: '',
				on_accent: '',
			},
		},
	},

	splash: {
		logo: '',
		background: '',
		logo_color: '',
	},
	login: {
		logo: '',
		notification_icon: '',
	},
	store: {
		name: '',
		cellphone_name: '',
		icon: '../../../../assets/default_logo.webp',
		images: [],
		colors: {
			background: '',
			text: '',
			icon: '',
		},
	},
	id: '',
	subgroup_id: 0,
};

export const STAGES = [
	{
		step: 1,
		path: 'tela-inicial',
	},
	{
		step: 2,
		path: 'tela-de-abertura',
	},
	{
		step: 3,
		path: 'tela-de-login',
	},
	{
		step: 4,
		path: 'loja',
	},
];
